import React from 'react';
import { Box, Typography } from '@mui/material';

const ViewWeeklyAvailability = ({ weeklyAvailability }) => {
  const daysOfWeek = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ];

  return (
    <Box sx={{ px:2, mt: 2, mb: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Weekly Availability</Typography>
      {daysOfWeek.map((day) => (
        <Box key={day} sx={{ mb: 1 }}>
          <Typography variant="body1"><strong>{day}:</strong> {weeklyAvailability[day].isClosed ? 'Closed' : `${weeklyAvailability[day].startTime} - ${weeklyAvailability[day].endTime}`}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ViewWeeklyAvailability;