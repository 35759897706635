import { createTheme } from '@mui/material/styles';

const tripSlipTheme = createTheme({
  palette: {
    primary: {
      light: '#FFE54C',
      main: '#000000',
      dark: '#C7A400',
    },
    secondary: {
      light: '#A6A6A6',
      main: '#808080',
      dark: '#5A5A5A',
    },
    schoolBus: {
      light: '#FFE066',
      main: '#FFCC00',
      dark: '#B38F00',
    },
    black: '#000000',
    white: '#FFFFFF',
  },
  typography: {
    fontFamily: 'Oswald, sans-serif',
    // Other typography settings...
  },
});

// ... rest of the theme file ...

const tripSlipLightTheme = createTheme({
  ...tripSlipTheme,
  palette: {
    ...tripSlipTheme.palette,
    mode: 'light',
    background: {
      default: '#FFCC00',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#212121',
      secondary: '#757575',
    },
  },
});

const tripSlipDarkTheme = createTheme({
  ...tripSlipTheme,
  palette: {
    ...tripSlipTheme.palette,
    mode: 'dark',
    background: {
      default: '#303030',
      paper: '#424242',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#BDBDBD',
    },
  },
});

export { tripSlipTheme, tripSlipLightTheme, tripSlipDarkTheme };