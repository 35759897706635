import React, { useState, useEffect } from "react";
import DashboardDrawer from "../../DashboardDrawers/VendorDashboardDrawer/VendorDashboardDrawer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./VendorDashboard.css";

const VendorDashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [data, setData] = useState({
    pendingBookings: [],
    upcomingMonthBookings: [],
    todayBookings: [],
  });
  const [showInfoBox, setShowInfoBox] = useState(
    !sessionStorage.getItem("vendorId")
  );
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SERVICE_URL;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const fetchBookings = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) return;

      try {
        const response = await axios.get(`${url}/users/dashboard`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (Array.isArray(response.data) && response.data.length === 0) {
          setShowInfoBox(true);
        } else {
          setData(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchBookings();
  }, [url]);

  const BookingCard = ({ booking, type }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6">{booking.organizationName}</Typography>
        <Typography variant="body2">
          Date: {new Date(booking.bookingDate).toLocaleDateString()}
        </Typography>
        {type === "pending" && (
          <>
            <Typography variant="body2">
              People: {booking.numberOfPeople}
            </Typography>
            <Typography variant="body2">
              Grades: {booking.grades.join(", ")}
            </Typography>
          </>
        )}
        {type === "today" && (
          <>
            <Typography variant="body2">
              Educator: {booking.educatorName}
            </Typography>
            <Typography variant="body2">
              Signed Slips: {booking.numberOfSignedSlips}
            </Typography>
            <Typography variant="body2">
              Time:{" "}
              {`${booking.timeSlot.startTime} - ${booking.timeSlot.endTime}`}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );

  return (
    <div>
      <DashboardDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
      <Container maxWidth="lg">
        {showInfoBox && (
          <Box className="info-box" sx={{ position: "relative", mb: 2, p: 2 }}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              If you want to show up in the TripSlips database, you need to
              enter your vendor information.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/view-vendor-information")}
            >
              Add Information
            </Button>
            <IconButton
              onClick={() => setShowInfoBox(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <Box className="dashboard-container">
          <Box className="dashboard-section">
            <Typography variant="h5" gutterBottom>
              Pending Bookings
            </Typography>
            {data.pendingBookings.length > 0 ? (
              isMobile ? (
                data.pendingBookings.map((booking) => (
                  <BookingCard
                    key={booking._id}
                    booking={booking}
                    type="pending"
                  />
                ))
              ) : (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>Booking Date</TableCell>
                        <TableCell>Number of People</TableCell>
                        <TableCell>Grades</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.pendingBookings.map((booking) => (
                        <TableRow key={booking._id}>
                          <TableCell>{booking.organizationName}</TableCell>
                          <TableCell>
                            {new Date(booking.bookingDate).toLocaleDateString()}
                          </TableCell>
                          <TableCell>{booking.numberOfPeople}</TableCell>
                          <TableCell>{booking.grades.join(", ")}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            ) : (
              <Typography variant="body1" align="center">
                None
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/pending-bookings")}
              sx={{ mt: 2 }}
            >
              View more
            </Button>
          </Box>

          <Box className="dashboard-section">
            <Typography variant="h5" gutterBottom>
              Today's Bookings
            </Typography>
            {data.todayBookings.length > 0 ? (
              isMobile ? (
                data.todayBookings.map((booking) => (
                  <BookingCard
                    key={booking._id}
                    booking={booking}
                    type="today"
                  />
                ))
              ) : (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Educator Name</TableCell>
                        <TableCell>Organization Name</TableCell>
                        <TableCell>Number of Signed Slips</TableCell>
                        <TableCell>Time Slot</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.todayBookings.map((booking) => (
                        <TableRow key={booking._id}>
                          <TableCell>{booking.educatorName}</TableCell>
                          <TableCell>{booking.organizationName}</TableCell>
                          <TableCell>{booking.numberOfSignedSlips}</TableCell>
                          <TableCell>{`${booking.timeSlot.startTime} - ${booking.timeSlot.endTime}`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            ) : (
              <Typography variant="body1" align="center">
                None
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default VendorDashboard;
