import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CreateTripSlipInfoForBooking from "./CreateTripSlipInfoForBooking";
import CreateTripSlipForm from "./CreateTripSlipForm";
import ConfirmTripSlipBooking from "./ConfirmTripSlipBooking";
import OrganizerAccessControl from "../AccessControls/organizerAccessControl";
import { styled } from "@mui/system";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import EditBookingForm from "../EditBookingForm/EditBookingForm";

const Logo = styled("img")(({ theme }) => ({
  height: "100px",
  marginRight: "16px",
  [theme.breakpoints.down("sm")]: {
    height: "60px",
    marginRight: "8px",
  },
}));

const CreateTripSlipScreen = () => {
  OrganizerAccessControl();
  const [currentScreen, setCurrentScreen] = useState("initial");
  const [tripInfo, setTripInfo] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    console.log(isEditing);
  }, [isEditing]);

  const handleCreateSlip = () => {
    setCurrentScreen("createSlip");
  };

  const handleBack = () => {
    setCurrentScreen("initial");
  };

  const handleNextFromTripInfo = (tripInfo) => {
    console.log(tripInfo);
    setTripInfo(tripInfo);
  };

  const handleSelectVendor = (selection) => {
    console.log(selection, tripInfo);
    setSelectedVendor(selection.vendor);
    setTripInfo({
      ...tripInfo,
      startTime: selection.startTime,
      endTime: selection.endTime,
      vendorId: selection.vendor._id,
    });
    setCurrentScreen("confirmBooking");
  };

  const onCancel = () => {
    setIsEditing(false);
  };

  const OptionCard = ({ title, description, features, buttonText, onClick, disabled }) => (
    <Paper
      sx={{
        p: { xs: 2, md: 5 },
        borderRadius: "5%",
        textAlign: "center",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
          {title}
        </Typography>
        <Typography variant={isMobile ? "body1" : "h5"} gutterBottom>
          {description}
        </Typography>
        <List>
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant={isMobile ? "body1" : "h5"} gutterBottom>
                      {feature}
                    </Typography>
                  }
                />
              </ListItem>
              {index < features.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Button
          size="large"
          variant="contained"
          onClick={onClick}
          disabled={disabled}
          fullWidth
        >
          <Typography variant={isMobile ? "body1" : "h6"}>{buttonText}</Typography>
        </Button>
        {disabled && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            Coming Soon! This feature is in development and will be available shortly.
          </Typography>
        )}
      </Box>
    </Paper>
  );

  return (
    <Box sx={{ p: { xs: 1, md: 4 } }}>
      {currentScreen === "initial" && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Logo src="/assets/images/logo.png" alt="Logo" />
            <Typography variant={isMobile ? "h5" : "h4"} color="primary" gutterBottom align="center">
              What Do You Want To Do?
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <OptionCard
                title="Book A Trip"
                description="Choose from verified trips and handle all booking details in one place."
                features={[
                  "Select Trips: Pick from pre-approved outings.",
                  "Easy Payments: Manage costs seamlessly.",
                  "Quick Slips: Generate digital permission slips instantly.",
                ]}
                buttonText="Book A Trip"
                onClick={() => {}}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OptionCard
                title="Create a Slip"
                description="Already have a trip planned? Quickly create digital slips for guardian approval."
                features={[
                  "Digital Signatures: Guardians sign slips online.",
                  "Child Info: Collect vital attendee information.",
                  "Organizer Dashboard: Keep track of all details easily.",
                ]}
                buttonText="Create A Slip"
                onClick={handleCreateSlip}
                disabled={false}
              />
            </Grid>
          </Grid>
        </>
      )}

      {currentScreen === "bookTrip" && (
        <CreateTripSlipInfoForBooking
          setTripInfo={setTripInfo}
          tripInfo={tripInfo}
          handleBack={handleBack}
          onVendorSelect={handleSelectVendor}
          onNext={handleNextFromTripInfo}
        />
      )}

      {currentScreen === "createSlip" && (
        <CreateTripSlipForm handleBack={handleBack} />
      )}

      {currentScreen === "confirmBooking" &&
        (isEditing ? (
          <EditBookingForm
            setIsEditing={setIsEditing}
            tripInfo={tripInfo}
            vendor={selectedVendor}
            onSave={handleNextFromTripInfo}
            onCancel={onCancel}
          />
        ) : (
          <ConfirmTripSlipBooking
            setIsEditing={setIsEditing}
            tripInfo={tripInfo}
            vendor={selectedVendor}
          />
        ))}
    </Box>
  );
};

export default CreateTripSlipScreen;