import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Card, CardContent, CardMedia, Typography, Grid, TextField, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const CreateBooking = ({ onVendorSelect }) => {
  
    const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const url = process.env.REACT_APP_SERVICE_URL;

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await axios.get(`${url}/vendorinformation/`);
        setVendors(response.data);
        setFilteredVendors(response.data);
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    fetchVendors();
  }, [url]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filtered = vendors.filter(
      (vendor) =>
        vendor.name.toLowerCase().includes(lowerCaseSearchTerm) ||
        vendor.type.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setFilteredVendors(filtered);
  };

  const selectVendor = (vendor) => {
    setSelectedVendor(vendor);
    setStartTime(null);
    setEndTime(null);
  };

  const confirmSelection = () => {
    if (selectedVendor && startTime && endTime) {
      onVendorSelect({
        vendor: selectedVendor,
        startTime: startTime.format('hh:mm A'),
        endTime: endTime.format('hh:mm A'),
      });
      setSelectedVendor(null);
    }
  };

  const renderTimeFields = () => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1">Select Start Time:</Typography>
        <TimePicker
          value={startTime}
          onChange={(newValue) => setStartTime(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          Select End Time:
        </Typography>
        <TimePicker
          value={endTime}
          onChange={(newValue) => setEndTime(newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </LocalizationProvider>
  );


  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Select a Vendor
      </Typography>
      <TextField
        label="Search by name or type"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <Button
        onClick={handleSearch}
        sx={{ mb: 2, backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#333' } }}
      >
        Search
      </Button>
      <Grid container spacing={3}>
        {filteredVendors.map((vendor) => (
          <Grid item xs={12} key={vendor._id}>
            <Card sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', mb: 2 }}>
              <CardMedia
                component="img"
                sx={{ width: 300, height: '100%', objectFit: 'cover' }}
                image={vendor.logo || 'default_logo_placeholder.png'}
                alt="Vendor logo"
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {vendor.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${vendor.type} - Capacity: ${vendor.venueCapacity}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${vendor.businessLocation.streetAddress}, ${vendor.businessLocation.city}, ${vendor.businessLocation.state} ${vendor.businessLocation.zipCode}`}
                  </Typography>
                  <Typography variant="body2">{vendor.businessDescription}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`Email: ${vendor.businessEmail}`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`Phone: ${vendor.businessPhoneNumber}`}
                  </Typography>
                </CardContent>
                <Button
                  onClick={() => selectVendor(vendor)}
                  sx={{ mt: 2, backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#333' } }}
                >
                  Select This Vendor
                </Button>
                {selectedVendor && selectedVendor._id === vendor._id && renderTimeFields()}
                {selectedVendor && selectedVendor._id === vendor._id && (
                  <Button
                    onClick={confirmSelection}
                    sx={{ mt: 2, backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: '#333' } }}
                  >
                    Confirm Time Selection
                  </Button>
                )}
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CreateBooking;