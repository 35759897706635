import React from 'react';
import { Box, TextField, Checkbox, FormControlLabel, Typography } from '@mui/material';

const EditWeeklyAvailability = ({ weeklyAvailability, onChange }) => {
  const daysOfWeek = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ];

  const handleTimeChange = (day, field, value) => {
    const updatedDay = {
      ...weeklyAvailability[day],
      [field]: value,
    };
    onChange({ ...weeklyAvailability, [day]: updatedDay });
  };

  const handleClosedChange = (day, isClosed) => {
    const updatedDay = {
      ...weeklyAvailability[day],
      isClosed,
    };
    onChange({ ...weeklyAvailability, [day]: updatedDay });
  };

  return (
    <Box sx={{ my: 2 }}>
      <Typography variant="h6">Weekly Availability</Typography>
      {daysOfWeek.map((day) => (
        <Box key={day} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ width: '100px' }}>{day}</Typography>
          <TextField
            label="Start Time"
            type="time"
            disabled={weeklyAvailability[day].isClosed}
            value={weeklyAvailability[day].startTime}
            onChange={(e) => handleTimeChange(day, 'startTime', e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mx: 1 }}
          />
          <TextField
            label="End Time"
            type="time"
            disabled={weeklyAvailability[day].isClosed}
            value={weeklyAvailability[day].endTime}
            onChange={(e) => handleTimeChange(day, 'endTime', e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mx: 1 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={weeklyAvailability[day].isClosed}
                onChange={(e) => handleClosedChange(day, e.target.checked)}
              />
            }
            label="Closed"
          />
        </Box>
      ))}
    </Box>
  );
};

export default EditWeeklyAvailability;