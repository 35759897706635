// EducatorDashboardDrawer.js
import React from 'react';
import { Typography, Drawer, List, ListItemButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

export default function EducatorDashboardDrawer({ open, toggleDrawer }) {
  const navigate = useNavigate();

  const menuItems = [
    { text: 'Home', path: '/organizer-dashboard' },
    { text: 'Create a TripSlip', path: '/create-trip-slip' },
    { text: 'View TripSlips', path: '/view-tripslips'},
    {text: 'Edit Profile', path: '/edit-profile'}
  ];

  // Logout function
  const handleLogout = () => {
    sessionStorage.clear(); // Clear all sessionStorage
    navigate('/'); // Navigate to the root path
  };


  return (
    <>
      {/* <AppBar position="static" sx={{ backgroundColor: 'black', color: 'white' }} className="appBar">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">TripSlip Organizer Portal</Typography>
          <div style={{ flexGrow: 1 }}></div>
          <Button color="inherit" onClick={handleLogout}>
            <ExitToAppIcon />
            Logout
          </Button>
        </Toolbar>
      </AppBar> */}
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)} sx={{ '& .MuiDrawer-paper': { backgroundColor: 'black', color: 'white',pt: 7, }}}>
        <div
          className="list"
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
              <ListItemButton button key={item.text} component={Link} to={item.path}>
                <Typography variant="subtitle1">{item.text}</Typography>
              </ListItemButton>
            ))}
            <ListItemButton button onClick={handleLogout}>
              <Typography variant="subtitle1">Logout</Typography>
            </ListItemButton>
          </List>
        </div>
      </Drawer>
    </>
  );
}