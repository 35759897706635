import React from "react";
import "./ResponseModal.css"; // Ensure this CSS file is updated with the styles above

const ResponseModal = ({ header, message, onClose }) => {
  return (
    <div className="response-modal-backdrop">
      <div className="response-modal-content">
        <h2>{header}</h2>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ResponseModal;
