import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
} from '@mui/material';
import logo from '../../assets/images/logo.png';

const Welcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { email, role } = location.state || {};

  useEffect(() => {
    if (!email || !role) {
      navigate('/');
    }
  }, [email, role, navigate]);

  const handleProceedClick = () => {
    navigate('/');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor="#FFD800"
    >
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ p: 4 }}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <img src={logo} alt="TripSlip Logo" style={{ maxWidth: '100%' }} />
            </Grid>
            <Grid item>
              <Typography variant="h4" align="center" gutterBottom>
                Welcome to TripSlip
              </Typography>
            </Grid>
            <Grid item>
              <Divider sx={{ width: '100%' }} />
            </Grid>
            <Grid item>
              <Typography variant="body1" align="center">
                A new account for <strong>{email}</strong> with role{' '}
                <strong>{role}</strong> has been set up.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleProceedClick}
              >
                Click to Proceed
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default Welcome;