import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import DashboardDrawer from '../../DashboardDrawers/VendorDashboardDrawer/VendorDashboardDrawer';
import { useNavigate } from 'react-router-dom';

const PendingBookings = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [filteredBookings, setFilteredBookings] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const url = process.env.REACT_APP_SERVICE_URL;
    const navigate = useNavigate();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setDrawerOpen(open);
    };

    const handleRowClick = (bookingId) => {
      navigate(`/view-booking/${bookingId}`);
    };

    useEffect(() => {
        const vendorId = sessionStorage.getItem('vendorId');
        const fetchPendingBookings = async () => {
          try {
            const response = await axios.get(`${url}/bookings/vendor/${vendorId}/pending`);
            setBookings(response.data);
            setFilteredBookings(response.data); // Initially display all bookings
          } catch (error) {
            console.error('Failed to fetch pending bookings:', error);
          }
        };
    
        if (vendorId) {
          fetchPendingBookings();
        }
    }, [url]);

    const handleAction = async (bookingId, action) => {
        try {
          await axios.post(`${url}/bookings/action/${bookingId}`, { action });
          // Remove the booking from both the full list and the filtered list
          const updatedBookings = bookings.filter(booking => booking._id !== bookingId);
          setBookings(updatedBookings);
          setFilteredBookings(updatedBookings.filter(booking =>
            booking.organizer.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            booking.organizer.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            booking.bookingDate.includes(searchTerm) ||
            booking.status.toLowerCase().includes(searchTerm.toLowerCase())
          ));
        } catch (error) {
          console.error(`Failed to ${action} booking:`, error);
        }
    };

    const handleSearch = () => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const results = bookings.filter(booking =>
          booking.organizer.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
          booking.organizer.lastName.toLowerCase().includes(lowerCaseSearchTerm) ||
          booking.bookingDate.includes(searchTerm) ||
          booking.status.toLowerCase().includes(lowerCaseSearchTerm)
        );
        setFilteredBookings(results);
    };

    return (
        <div>
            <DashboardDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
            <Container>
            <Box sx={{ mt: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <TextField
                    label="Search"
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ width: '50%' }}
                    />
                    <Button variant="contained" onClick={handleSearch} sx={{ ml: 2, backgroundColor: 'black', color: 'white' }}>
                    Submit
                    </Button>
                </Box>
                <TableContainer component={Paper}>
                    <Table>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: 'black' }}>
                        <TableCell sx={{ color: 'white' }}>TripSlip Code</TableCell>
                        <TableCell sx={{ color: 'white' }}>Organizer Name</TableCell>
                        <TableCell sx={{ color: 'white' }}>Booking Date</TableCell>
                        <TableCell sx={{ color: 'white' }}>Time Slot</TableCell>
                        <TableCell sx={{ color: 'white' }}>Number Of People</TableCell>
                        <TableCell sx={{ color: 'white' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredBookings.length > 0 ? (
                        filteredBookings.map((booking) => (
                            <TableRow key={booking._id} onClick={() => handleRowClick(booking._id)}>
                            <TableCell>{booking.tripSlipCode}</TableCell>
                            <TableCell>{`${booking.organizer.firstName} ${booking.organizer.lastName}`}</TableCell>
                            <TableCell>{booking.bookingDate}</TableCell>
                            <TableCell>{`${booking.timeSlot.startTime} - ${booking.timeSlot.endTime}`}</TableCell>
                            <TableCell>{booking.numberOfPeople}</TableCell>
                            <TableCell>
                                <Button variant="contained" onClick={() => handleAction(booking._id, 'approve')} sx={{ mr: 1, backgroundColor: 'green', '&:hover': { backgroundColor: '#045d04' } }}>
                                Approve
                                </Button>
                                <Button variant="contained" onClick={() => handleAction(booking._id, 'deny')} sx={{ backgroundColor: 'red', '&:hover': { backgroundColor: '#8b0000' } }}>
                                Deny
                                </Button>
                            </TableCell>
                            </TableRow>
                        ))
                        ) : (
                        <TableRow>
                            <TableCell colSpan={7} align="center">No pending bookings for this vendor</TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            </Container>
        </div>
    )
};

export default PendingBookings;