import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { Drawer, List, ListItem, ListItemText } from "@mui/material";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Welcome from "./components/Welcome/Welcome";
import OrganizerDashboard from "./components/Dashboards/OrganizerDashboard/OrganizerDashboard";
import VendorDashboard from "./components/Dashboards/VendorDashboard/VendorDashboard";
import CreateTripSlipScreen from "./components/CreatingTripSlips/CreateTripSlipScreen";
import ViewTripSlip from "./components/TripSlips/ViewTripSlip";
import ViewTripSlips from "./components/TripSlips/ViewTripSlips";
import SignTripSlip from "./components/TripSlips/SignTripSlip/SignTripSlip";
import EditProfile from "./components/EditProfile/EditProfile";
import ViewVendorInformation from "./components/VendorInformation/ViewVendorInformation/ViewVendorInformation";
import EditVendorInformation from "./components/VendorInformation/EditVendorInformation/EditVendorInformation";
import ViewAllBookings from "./components/Bookings/ViewAllBookings/ViewAllBookings";
import PendingBookings from "./components/Bookings/PendingBookings/PendingBookings";
import ViewBooking from "./components/Bookings/ViewBooking/ViewBooking";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const AppRouter = ({ drawerOpen, toggleDrawer }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { text: "Login", path: "/" },
    { text: "Signup", path: "/signup" },
    { text: "Welcome", path: "/welcome" },
    { text: "Privacy Policy", path: "/privacy-policy" },
    { text: "Terms & Conditions", path: "/terms-and-conditions" },
  ];
  const OrganizerMenuItems = [
    { text: "Dashboard", path: "/organizer-dashboard" },
    { text: "Create Trip Slip", path: "/create-trip-slip" },
    { text: "View Trip Slips", path: "/view-tripslips" },
    { text: "Edit Profile", path: "/edit-profile" },
    { text: "Privacy Policy", path: "/privacy-policy" },
    { text: "Terms & Conditions", path: "/terms-and-conditions" },
  ];
  const vendorMenuItems = [
    { text: "Dashboard", path: "/vendor-dashboard" },
    { text: "Edit Profile", path: "/edit-profile" },
    { text: "View Information", path: "/view-vendor-information" },
    { text: "Edit Information", path: "/edit-vendor-information" },
    { text: "Bookings", path: "/view-bookings" },
    { text: "Pending Bookings", path: "/pending-bookings" },
    { text: "Privacy Policy", path: "/privacy-policy" },
    { text: "Terms & Conditions", path: "/terms-and-conditions" },
  ];

  const handleMenuItemClick = (path) => {
    navigate(path);
    toggleDrawer(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Drawer
        anchor="left"
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "black",
            color: "white",
            pt: 7,
          },
        }}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <List>
          {!sessionStorage.userId &&
            menuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={() => handleMenuItemClick(item.path)}
                selected={location.pathname === item.path}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            ))}

          {sessionStorage.userId &&
            sessionStorage.role === "organizer" &&
            OrganizerMenuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={() => handleMenuItemClick(item.path)}
                selected={location.pathname === item.path}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            ))}

          {sessionStorage.userId &&
            sessionStorage.role === "vendor" &&
            vendorMenuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={() => handleMenuItemClick(item.path)}
                selected={location.pathname === item.path}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
        </List>
      </Drawer>
      {/* {sessionStorage.userId && sessionStorage.role === 'organizer' && <OrganizerDashboardDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />} */}
      {/* {sessionStorage.userId && sessionStorage.role === 'vendor' && <VendorDashboardDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />} */}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/organizer-dashboard" element={<OrganizerDashboard />} />
        <Route path="/vendor-dashboard" element={<VendorDashboard />} />
        <Route path="/create-trip-slip" element={<CreateTripSlipScreen />} />
        <Route path="/view-trip-slip/:tripSlipId" element={<ViewTripSlip />} />
        <Route path="/view-tripslips" element={<ViewTripSlips />} />
        <Route path="/sign-trip-slip" element={<SignTripSlip />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route
          path="/view-vendor-information"
          element={<ViewVendorInformation />}
        />
        <Route
          path="/edit-vendor-information"
          element={<EditVendorInformation />}
        />
        <Route path="/view-bookings" element={<ViewAllBookings />} />
        <Route path="/pending-bookings" element={<PendingBookings />} />
        <Route path="/view-booking/:bookingId" element={<ViewBooking />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </LocalizationProvider>
  );
};

export default AppRouter;
