import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  ListItemText,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./CreateTripSlipForm.css"; // Import custom CSS for modal

const CreateTripSlipForm = () => {
  const [formState, setFormState] = useState({
    tripName: "",
    grades: [],
    maxNumOfStudents: "",
    tripSlipDate: new Date(),
    departureLocation: {
      buildingName: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
    },
    destinationLocation: {
      buildingName: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
    },
    cost: 0,
    hasCost: false,
    notes: "",
  });
  const [termsFile, setTermsFile] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [tripSlipId, setTripSlipId] = useState(null);
  const navigate = useNavigate();

  const url = process.env.REACT_APP_SERVICE_URL;
  const token = sessionStorage.getItem("token");
  const config = { headers: { Authorization: `Bearer ${token}` } };

  const SuccessModal = ({ onClose, onView }) => (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <p>Your TripSlip Has Been Created!</p>
        <div style={{ textAlign: "center" }}>
          <Button onClick={onView}>View</Button>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </div>
  );

  const LoadingModal = () => (
    <div className="modal-backdrop">
      <div className="modal">
        <p>Loading...</p>
      </div>
    </div>
  );

  const ErrorModal = ({ onClose }) => (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <p>An error occurred. Please try again later.</p>
        <div style={{ textAlign: "center" }}>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </div>
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleLocationChange = (e, locationType) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [locationType]: { ...formState[locationType], [name]: value },
    });
  };

  const handleGradeChange = (event) => {
    const selectedGrades = event.target ? event.target.value : event;
    setFormState((prevState) => ({
      ...prevState,
      grades: selectedGrades,
    }));
  };

  const handleCostChange = (e) => {
    const { checked } = e.target;
    setFormState({
      ...formState,
      hasCost: checked,
      cost: checked ? formState.cost : 0,
    });
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoadingModal(true);

    const formData = new FormData();

    Object.keys(formState).forEach((key) => {
      if (typeof formState[key] === "object" && formState[key] !== null) {
        formData.append(key, JSON.stringify(formState[key]));
      } else {
        formData.append(key, formState[key]);
      }
    });

    if (termsFile) {
      formData.append("termsFile", termsFile);
    }

    try {
      const response = await axios.post(`${url}/tripslips`, formData, {
        headers: {
          ...config.headers,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowLoadingModal(false);

      if (response.status === 201) {
        setTripSlipId(response.data._id);
        setShowSuccessModal(true);
      }
    } catch (error) {
      setShowLoadingModal(false);
      setShowErrorModal(true);
      console.error("Error submitting trip slip:", error);
    }
  };

  const handleClose = () => {
    setShowSuccessModal(false);
    setShowErrorModal(false);
    if (tripSlipId) {
      navigate(`/view-tripslips`);
    }
  };

  const handleView = () => {
    setShowSuccessModal(false);
    navigate(`/view-trip-slip/${tripSlipId}`);
  };

  const grades = [
    "Pre-K",
    "Kindergarten",
    "1st",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
  ];
  const steps = [
    "Trip Details",
    "Departure Location",
    "Destination Location",
    "Add Notes",
    "Confirm",
  ];

  const goBack = () => {
    window.location.reload();
  };

  return (
    <Box sx={{}}>
      <Button onClick={() => goBack()}>Go Back</Button>

      <Paper elevation={3} sx={{ p: 0 }}>
        <Typography variant="h4" sx={{ mb: 4, px: 2 }}>
          Create A TripSlip
        </Typography>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit}>
          {activeStep === 0 && (
            <Grid container spacing={3} sx={{ p: 3 }}>
              <Grid item xs={12} md={2}>
                <DatePicker
                  selected={formState.tripSlipDate}
                  onChange={(date) =>
                    setFormState({ ...formState, tripSlipDate: date })
                  }
                  customInput={
                    <TextField
                      label="Trip Slip Date"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Trip Name"
                  name="tripName"
                  value={formState.tripName}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  label="Max Number of Students"
                  type="number"
                  name="maxNumOfStudents"
                  value={formState.maxNumOfStudents}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">Grades</Typography>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Grades</InputLabel>
                  <Select
                    multiple
                    value={formState.grades}
                    onChange={handleGradeChange}
                    label="Grades"
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {grades.map((grade) => (
                      <MenuItem key={grade} value={grade}>
                        <Checkbox checked={formState.grades.includes(grade)} />
                        <ListItemText primary={grade} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} display={"flex"}>
                <TextField
                  label="Cost (USD)"
                  type="number"
                  name="cost"
                  value={formState.cost}
                  onChange={handleInputChange}
                  disabled={!formState.hasCost}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    startAdornment: <span>$</span>,
                  }}
                  sx={{ mr: 3 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="hasCost"
                      checked={formState.hasCost}
                      onChange={handleCostChange}
                    />
                  }
                  label="Has Cost?"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Upload Terms & Conditions (PDF only)
                </Typography>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e) => setTermsFile(e.target.files[0])}
                  style={{ width: "100%", padding: "10px 0" }}
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid container spacing={3} sx={{ p: 3 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Departure Location</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Building Name"
                  name="buildingName"
                  value={formState.departureLocation.buildingName}
                  onChange={(e) => handleLocationChange(e, "departureLocation")}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Street Address"
                  name="streetAddress"
                  value={formState.departureLocation.streetAddress}
                  onChange={(e) => handleLocationChange(e, "departureLocation")}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="city"
                  value={formState.departureLocation.city}
                  onChange={(e) => handleLocationChange(e, "departureLocation")}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="State"
                  name="state"
                  value={formState.departureLocation.state}
                  onChange={(e) => handleLocationChange(e, "departureLocation")}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Zipcode"
                  name="zipCode"
                  value={formState.departureLocation.zipCode}
                  onChange={(e) => handleLocationChange(e, "departureLocation")}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid container spacing={3} sx={{ p: 3 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Destination Location
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Building Name"
                  name="buildingName"
                  value={formState.destinationLocation.buildingName}
                  onChange={(e) =>
                    handleLocationChange(e, "destinationLocation")
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Street Address"
                  name="streetAddress"
                  value={formState.destinationLocation.streetAddress}
                  onChange={(e) =>
                    handleLocationChange(e, "destinationLocation")
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="City"
                  name="city"
                  value={formState.destinationLocation.city}
                  onChange={(e) =>
                    handleLocationChange(e, "destinationLocation")
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="State"
                  name="state"
                  value={formState.destinationLocation.state}
                  onChange={(e) =>
                    handleLocationChange(e, "destinationLocation")
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Zipcode"
                  name="zipCode"
                  value={formState.destinationLocation.zipCode}
                  onChange={(e) =>
                    handleLocationChange(e, "destinationLocation")
                  }
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
          {activeStep === 3 && (
            <Grid container spacing={3} sx={{ p: 3 }}>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Notes from the Organizer
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Notes"
                  name="notes"
                  value={formState.notes}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
          {activeStep === steps.length - 1 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                mt: 4,
              }}
            >
              <Grid container spacing={3} sx={{ p: 3 }}>
                <Grid item xs={12}>
                  <Typography variant="h6">Confirm Details</Typography>
                  <Typography variant="subtitle1">
                    Trip Name: {formState.tripName}
                  </Typography>
                  <Typography variant="subtitle1">
                    Trip Slip Date:{" "}
                    {formState.tripSlipDate.toLocaleDateString()}
                  </Typography>
                  <Typography variant="subtitle1">
                    Grades: {formState.grades.join(", ")}
                  </Typography>
                  <Typography variant="subtitle1">
                    Max Number of Students: {formState.maxNumOfStudents}
                  </Typography>
                  <Typography variant="subtitle1">
                    Cost: {formState.hasCost ? `$${formState.cost}` : "Free"}
                  </Typography>
                  <Typography variant="subtitle1">
                    Departure Location:{" "}
                    {`${formState.departureLocation.buildingName}, ${formState.departureLocation.streetAddress}, ${formState.departureLocation.city}, ${formState.departureLocation.state} ${formState.departureLocation.zipCode}`}
                  </Typography>
                  <Typography variant="subtitle1">
                    Destination Location:{" "}
                    {`${formState.destinationLocation.buildingName}, ${formState.destinationLocation.streetAddress}, ${formState.destinationLocation.city}, ${formState.destinationLocation.state} ${formState.destinationLocation.zipCode}`}
                  </Typography>
                  <Typography variant="subtitle1">
                    Notes: {formState.notes}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "space-between", m: 4 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
            >
              {activeStep === steps.length - 1 ? "Create Trip Slip" : "Next"}
            </Button>
          </Box>
        </form>
      </Paper>
      {showSuccessModal && (
        <SuccessModal onClose={handleClose} onView={handleView} />
      )}
      {showLoadingModal && <LoadingModal />}
      {showErrorModal && <ErrorModal onClose={handleClose} />}
    </Box>
  );
};

export default CreateTripSlipForm;
