import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Typography,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,Checkbox, ListItemText
} from '@mui/material';
import CreateBooking from './CreateBooking';
import DatePicker from 'react-datepicker';



const CreateTripSlipInfoForBooking = ({ onVendorSelect, onNext, setTripInfo, tripInfo }) => {

  const [tripName, setTripName] = useState('');
  const [grades, setGrades] = useState([]);
  const [maxNumOfStudents, setMaxNumOfStudents] = useState('');
  const [tripSlipDate, setTripSlipDate] = useState('');
  const [departureLocation, setDepartureLocation] = useState({
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [activeStep, setActiveStep] = useState(0);
  
  useEffect(()=>{
    setTripInfo({...tripInfo, tripName })
  },[tripName, setTripInfo, tripInfo])

    
  useEffect(()=>{
    setTripInfo({...tripInfo, grades: grades })
  },[grades,setTripInfo, tripInfo])

  useEffect(()=>{
    setTripInfo({...tripInfo, maxNumOfStudents })
  },[maxNumOfStudents, tripInfo, setTripInfo])

    
  useEffect(()=>{
    setTripInfo({...tripInfo, tripSlipDate })
  },[tripSlipDate, tripInfo, setTripInfo])

  const goBack = ()=>{
    window.location.reload();
  }

  useEffect(()=>{
    setTripInfo({...tripInfo, departureLocation})
  },[departureLocation, setTripInfo, tripInfo])
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleGradeChange = (event) => {
    const { value } = event.target;
    setGrades(value);
  };

  const handleLocationChange = (event) => {
    const { name, value } = event.target;
    setDepartureLocation((prevLocation) => ({
      ...prevLocation,
      [name]: value,
    }));
  };
  const isValidZipCode = (zipCode) => {
    const zipCodePattern = /^\d{5}$/;
    return zipCodePattern.test(zipCode);
  };
  const steps = ['Trip Details', 'Departure Location', 'Select Vendor', 'Confirm'];
  const grades_ = ['Pre-K','Kindergarten', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th'];

  return (
    <Box sx={{ }}>
      <Button onClick={()=>goBack()}> Go Back </Button>
      <Paper elevation={3} sx={{ p: 2 }}>
        <Typography variant="h4" sx={{ mb: 4 }}>
          Book A Trip
        </Typography>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <TextField
                label="Trip Name"
                value={tripName}
                onChange={(e) => setTripName(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Max Number of Students"
                type="number"
                value={maxNumOfStudents}
                onChange={(e) => setMaxNumOfStudents(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DatePicker
                selected={tripSlipDate}
                onChange={(date) => {
                  setTripSlipDate(date);
                  setTripInfo({ ...tripInfo, tripSlipDate: date });
                }}
                customInput={
                  <TextField
                    label="Trip Slip Date"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                }
              />
            </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Grades</Typography>
                {/* {isMobile ? ( */}
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Grades</InputLabel>
                    <Select
                      multiple
                      value={grades}
                      onChange={handleGradeChange}
                      label="Grades"
                      renderValue={(selected) => selected.join(', ')}
                    >
                      {grades_.map((grade) => (
                        <MenuItem key={grade} value={grade}>
                          <Checkbox checked={grades.includes(grade)} />
                          <ListItemText primary={grade} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </Grid>
          </Grid>
        )}
        {activeStep === 1 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Departure Location</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Building Name"
                name="buildingName"
                value={departureLocation.buildingName}
                onChange={(e) => handleLocationChange(e, 'departure')}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Street Address"
                name="streetAddress"
                value={departureLocation.streetAddress}
                onChange={handleLocationChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="city"
                value={departureLocation.city}
                onChange={handleLocationChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                name="state"
                value={departureLocation.state}
                onChange={handleLocationChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Zip Code"
                name="zipCode"
                value={departureLocation.zipCode}
                onChange={handleLocationChange}
                fullWidth
                variant="outlined"
                inputProps={{
                  maxLength: 5,
                  pattern: '[0-9]*',
                }}
                error={!isValidZipCode(departureLocation.zipCode)}
                helperText={!isValidZipCode(departureLocation.zipCode) && 'Please enter a valid 5-digit zip code'}
              />
            </Grid>
          </Grid>
        )}
        {activeStep === 2 && (
          
          <CreateBooking
          onVendorSelect={onVendorSelect}
            tripInfo={{
              tripName,
              grades,
              maxNumOfStudents,
              tripSlipDate,
              departureLocation,
            }}
            handleBack={handleBack}
          />
        )}
        {activeStep === 3 && (
          <Box>
            Confirm Information
            have a confirm checkbox and dispplay info from form in readible way
            TS-44
          </Box>
        ) }
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={
              activeStep === steps.length - 1
                ? () =>
                    onNext({
                      tripName,
                      grades,
                      maxNumOfStudents,
                      tripSlipDate,
                      departureLocation,
                    })
                : handleNext
            }
            sx={{
              backgroundColor: 'primary.main',
              color: 'white',
              '&:hover': { backgroundColor: 'primary.dark' },
            }}
          >
            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CreateTripSlipInfoForBooking;