import React, { useState } from "react";
import Axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Modal,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff, Check, Clear } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
    resetCode: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const url = process.env.REACT_APP_SERVICE_URL;

  useState(() => {
    if (location.state && location.state.email) {
      setFormData((prevState) => ({
        ...prevState,
        email: location.state.email,
      }));
    }
  }, [location.state]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const passwordsMatch =
    formData.password &&
    formData.confirmPassword &&
    formData.password === formData.confirmPassword;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!passwordsMatch) {
      setError("Passwords do not match.");
      return;
    }
    try {
      const response = await Axios.post(
        `${url}/users/reset-password`,
        formData
      );
      if (response.status === 200) {
        setMessage("");
        setError("");
        setModalOpen(true);
      } else {
        setError(
          response.data.message ||
            "Unable to reset your password. Please try again later."
        );
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Unable to reset your password. Please try again later."
      );
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    navigate("/");
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper elevation={3} sx={{ p: 4, width: "100%" }}>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          align="center"
          gutterBottom
        >
          Reset your Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            fullWidth
            margin="normal"
            disabled
          />
          <TextField
            label="Reset Code"
            name="resetCode"
            value={formData.resetCode}
            onChange={handleInputChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            required
            fullWidth
            margin="normal"
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type={showPassword ? "text" : "password"}
            required
            fullWidth
            margin="normal"
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={togglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  {formData.confirmPassword.length >=
                    formData.password.length &&
                    (passwordsMatch ? (
                      <Check color="success" />
                    ) : (
                      <Clear color="error" />
                    ))}
                </InputAdornment>
              ),
            }}
          />
          {error && (
            <Typography
              variant="body2"
              color="error"
              align="center"
              sx={{ mt: 2 }}
            >
              {error}
            </Typography>
          )}
          {message && (
            <Typography
              variant="body2"
              color="primary"
              align="center"
              sx={{ mt: 2 }}
            >
              {message}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
          >
            Reset Password
          </Button>
        </form>
      </Paper>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Your password has been updated.
          </Typography>
          <Typography variant="body2" gutterBottom>
            You can now use it to log in.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseModal}
            sx={{ mt: 2 }}
          >
            Go to Login
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default ResetPassword;
