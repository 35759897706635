import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Divider
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DashboardDrawer from '../../DashboardDrawers/VendorDashboardDrawer/VendorDashboardDrawer';

const ViewBookings = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [booking, setBooking] = useState(null);
    const url = process.env.REACT_APP_SERVICE_URL;
    const { bookingId } = useParams();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
        setDrawerOpen(open);
    };

    useEffect(() => {
        const fetchData = async () => {
          const response = await axios.get(`${url}/bookings/${bookingId}`);
          setBooking(response.data);
        };
        fetchData();
    }, [url, bookingId]);

    const handleAction = async (id, action) => {
        try {
            await axios.post(`${url}/bookings/action/${id}`, { action });
        } catch (error) {
            console.error(`Failed to '${action}' booking:`, error);
        }
    };

    return (
       <Container>
            <DashboardDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
            <Box sx={{ mt: 4, mb: 4 }}>
                <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography variant="h4" gutterBottom>
                        Booking Details
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1"><strong>Trip Name:</strong> {booking?.tripName}</Typography>
                            <Typography variant="subtitle1"><strong>Trip Code:</strong> {booking?.tripSlipCode}</Typography>
                            <Typography variant="subtitle1"><strong>Departure:</strong> {`${booking?.departureLocation.streetAddress}, ${booking?.departureLocation.city}, ${booking?.departureLocation.state}, ${booking?.departureLocation.zipCode}`}</Typography>
                            <Typography variant="subtitle1"><strong>Organizer:</strong> {booking?.organizer.firstName} {booking?.organizer.lastName}</Typography>
                            <Typography variant="subtitle1"><strong>Email:</strong> {booking?.organizerEmail}</Typography>
                            <Typography variant="subtitle1"><strong>Contact:</strong> {booking?.organizerContactPhone}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1"><strong>Booking Date:</strong> {booking?.bookingDate}</Typography>
                            <Typography variant="subtitle1"><strong>Time Slot:</strong> {`${booking?.timeSlot.startTime} - ${booking?.timeSlot.endTime}`}</Typography>
                            <Typography variant="subtitle1"><strong>Number of People:</strong> {booking?.numberOfPeople}</Typography>
                            <Typography variant="subtitle1"><strong>Status:</strong> {booking?.status}</Typography>
                            {booking?.grades.map(grade => (
                                <Typography key={grade} variant="subtitle1"><strong>Grade:</strong> {grade}</Typography>
                            ))}
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {booking?.status === 'pending' && (
                            <>
                                <Button variant="contained" color="success" onClick={() => handleAction(booking?._id, 'approve')} sx={{ mr: 1 }}>
                                    Approve
                                </Button>
                                <Button variant="contained" color="error" onClick={() => handleAction(booking?._id, 'deny')}>
                                    Deny
                                </Button>
                            </>
                        )}
                        {booking?.status === 'approved' && (
                            <Button variant="contained" color="secondary" onClick={() => handleAction(booking?._id, 'cancel')}>
                                Cancel
                            </Button>
                        )}
                    </Box>
                </Paper>
            </Box>
       </Container>
    );
}

export default ViewBookings;
