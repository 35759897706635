import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Grid,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import OrganizationAccessControl from "../../AccessControls/organizerAccessControl";
import "./OrganizerDashboard.css";

const OrganizerDashboard = () => {
  OrganizationAccessControl();
  const [dashboardData, setDashboardData] = useState({
    upcomingTrips: [],
    pastTrips: [],
    completedTripCount: 0,
    pendingBookings: [],
    signedTripSlips: [],
  });
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SERVICE_URL;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem("token");
      if (!token) return;

      try {
        const response = await axios.get(`${url}/users/dashboard`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const data = response.data;

        // Parse the locations before setting the state
        data.upcomingTrips = data.upcomingTrips.map((trip) => ({
          ...trip,
          departureLocation: JSON.parse(trip.departureLocation),
          destinationLocation: JSON.parse(trip.destinationLocation),
        }));

        data.pastTrips = data.pastTrips.map((trip) => ({
          ...trip,
          departureLocation: JSON.parse(trip.departureLocation),
          destinationLocation: JSON.parse(trip.destinationLocation),
        }));

        setDashboardData(data);
      } catch (error) {
        console.error("Error fetching educator dashboard data:", error);
      }
    };

    fetchData();
  }, [url]);

  const noTripsOrBookings =
    dashboardData.upcomingTrips.length === 0 &&
    dashboardData.pendingBookings.length === 0;

  const handleRowClick = (tripSlipId) => {
    navigate(`/view-trip-slip/${tripSlipId}`);
  };

  const TripCard = ({ trip }) => (
    <Card
      sx={{ mb: 2, cursor: "pointer" }}
      onClick={() => handleRowClick(trip._id)}
    >
      <CardContent>
        <Typography variant="h6">{trip.tripName}</Typography>
        <Typography variant="body2">
          Grades: {trip.grades.join(", ")}
        </Typography>
        <Typography variant="body2">
          Students: {trip.maxNumOfStudents}
        </Typography>
        <Typography variant="body2">
          Destination: {trip.destinationLocation.buildingName}
        </Typography>
      </CardContent>
    </Card>
  );

  const BookingCard = ({ booking }) => (
    <Card
      sx={{ mb: 2, cursor: "pointer" }}
      onClick={() => handleRowClick(booking._id)}
    >
      <CardContent>
        <Typography variant="h6">{booking.tripName}</Typography>
        <Typography variant="body2">
          Grades: {booking.grades.join(", ")}
        </Typography>
        <Typography variant="body2">Vendor: {booking.vendorName}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <div className="dashboard-container">
      <Container maxWidth="lg">
        <Box mt={4}>
          {noTripsOrBookings && (
            <Box mb={4} className="no-trips-or-bookings-box">
              <Typography variant="h6" className="no-trips-or-bookings-title">
                Welcome to TripSlip
              </Typography>
              <Typography variant="body1" className="no-trips-or-bookings-text">
                It looks like you don't have any upcoming trips or pending
                bookings.
              </Typography>
              <Button
                className="no-trips-or-bookings-button"
                onClick={() => navigate("/create-trip-slip")}
              >
                Create a Trip
              </Button>
              <Typography
                variant="body2"
                className="no-trips-or-bookings-description"
              >
                To get started with TripSlip, click the "Create a Trip" button
                above to schedule your first trip. You'll be able to manage all
                your trips and bookings here on your dashboard.
              </Typography>
            </Box>
          )}

          <Grid container spacing={4} direction="column">
            {/* Upcoming Trips Section */}
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                  Upcoming Trips
                </Typography>
                {dashboardData.upcomingTrips.length > 0 ? (
                  isMobile ? (
                    dashboardData.upcomingTrips.map((trip) => (
                      <TripCard key={trip._id} trip={trip} />
                    ))
                  ) : (
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                    >
                      <Table>
                        <TableHead className="tableHead">
                          <TableRow>
                            <TableCell>Trip Name</TableCell>
                            <TableCell>Grades</TableCell>
                            <TableCell>Students</TableCell>
                            <TableCell>Destination</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dashboardData.upcomingTrips.map((trip) => (
                            <TableRow
                              key={trip._id}
                              onClick={() => handleRowClick(trip._id)}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>{trip.tripName}</TableCell>
                              <TableCell>{trip.grades.join(", ")}</TableCell>
                              <TableCell>{trip.maxNumOfStudents}</TableCell>
                              <TableCell>
                                {trip.destinationLocation.buildingName}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                ) : (
                  <Typography variant="body1">No upcoming trips</Typography>
                )}
                <Button
                  component="button"
                  className="showMoreButton"
                  onClick={() => navigate("/view-tripslips")}
                >
                  View All
                </Button>
              </Box>
              <Divider />
            </Grid>

            {/* Pending Bookings Section */}
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                  Pending Bookings
                </Typography>
                {dashboardData.pendingBookings.length > 0 ? (
                  isMobile ? (
                    dashboardData.pendingBookings.map((booking) => (
                      <BookingCard key={booking._id} booking={booking} />
                    ))
                  ) : (
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                    >
                      <Table>
                        <TableHead className="tableHead">
                          <TableRow>
                            <TableCell>Trip Name</TableCell>
                            <TableCell>Grades</TableCell>
                            <TableCell>Vendor Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dashboardData.pendingBookings.map((booking) => (
                            <TableRow
                              key={booking._id}
                              onClick={() => handleRowClick(booking._id)}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>{booking.tripName}</TableCell>
                              <TableCell>{booking.grades.join(", ")}</TableCell>
                              <TableCell>{booking.vendorName}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                ) : (
                  <Typography variant="body1">No pending bookings</Typography>
                )}
                <Button
                  component="button"
                  className="showMoreButton"
                  onClick={() => navigate("/view-bookings")}
                >
                  View All
                </Button>
              </Box>
              <Divider />
            </Grid>

            {/* Past Trips Section */}
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                  Past Trips | {dashboardData.completedTripCount} Trips
                  Completed
                </Typography>
                {dashboardData.pastTrips.length > 0 ? (
                  isMobile ? (
                    dashboardData.pastTrips.map((trip) => (
                      <TripCard key={trip._id} trip={trip} />
                    ))
                  ) : (
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                    >
                      <Table>
                        <TableHead className="tableHead">
                          <TableRow>
                            <TableCell>Trip Name</TableCell>
                            <TableCell>Grades</TableCell>
                            <TableCell>Students</TableCell>
                            <TableCell>Destination</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dashboardData.pastTrips.map((trip) => (
                            <TableRow
                              key={trip._id}
                              onClick={() => handleRowClick(trip._id)}
                              style={{ cursor: "pointer" }}
                            >
                              <TableCell>{trip.tripName}</TableCell>
                              <TableCell>{trip.grades.join(", ")}</TableCell>
                              <TableCell>{trip.maxNumOfStudents}</TableCell>
                              <TableCell>
                                {trip.destinationLocation.buildingName}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                ) : (
                  <Typography variant="body1">No past trips</Typography>
                )}
                <Button
                  component="button"
                  className="showMoreButton"
                  onClick={() => navigate("/view-tripslips")}
                >
                  View All
                </Button>
              </Box>
              <Divider />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default OrganizerDashboard;
