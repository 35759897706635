import React, { useState } from "react";
import axios from "axios";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import TripSlipCodeModal from "../TripSlipCodeModal/TripSlipCodeModal";
import {
  Button,
  Container,
  TextField,
  Typography,
  Box,
  Grid,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const url = process.env.REACT_APP_SERVICE_URL;
  let navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    let loginUrl = `${url}/users/login`;
    try {
      const response = await axios.post(loginUrl, { email, password });
      if (response.status === 200) {
        // Save response data to session storage
        sessionStorage.setItem("user", response.data);
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("userId", response.data.userId);
        sessionStorage.setItem("firstName", response.data.firstName);
        sessionStorage.setItem("lastName", response.data.lastName);
        sessionStorage.setItem("role", response.data.role);

        const role = response.data.role;
        if (role === "vendor") {
          if (response.data.vendorInformationId) {
            sessionStorage.setItem(
              "vendorId",
              response.data.vendorInformationId
            );
          }
          navigate("/vendor-dashboard");
        } else if (role === "organizer") {
          navigate("/organizer-dashboard");
        } else if (role === "guardian") {
          // Handle guardian logic
        }
      } else {
        setErrorMessage("Error. Please check your email and password.");
        setShowModal(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage("Error. Please check your email and password.");
      setShowModal(true);
    }
  };

  const handleTripSlipSubmit = async (tripSlipCode, setError) => {
    try {
      const response = await axios.get(
        `${url}/tripslips/tripslipcode/${tripSlipCode}`,
        { tripSlipCode }
      );
      if (response.status === 200) {
        navigate("/sign-trip-slip", { state: { tripSlipData: response.data } });
      } else {
        setError("Trip slip code is incorrect");
      }
    } catch (error) {
      setError("Trip slip code is incorrect");
    }
  };

  return (
    <Box className="login-container">
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModal(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid container className="full-height">
        <Grid item xs={12} md={6} className="image-side">
          {isMobile && (
            <Box className="mobile-overlay">
              <Container maxWidth="xs" className="mobile-content">
                <Box className="logo-container">
                  <img src={logo} alt="Company Logo" className="logo" />
                  <Typography
                    variant="subtitle1"
                    align="center"
                    className="tagline"
                  >
                    Fieldtrip Logistics Made Easy
                  </Typography>
                </Box>
                <form onSubmit={handleSubmit} className="login-form">
                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    type="password"
                    label="Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="submit-button"
                  >
                    Login
                  </Button>
                  <Box className="links">
                    <Link href="/signup" variant="body2">
                      Don't have an account? Signup
                    </Link>
                    <br />
                    <Link href="/forgot-password" variant="body2">
                      Forgot password?
                    </Link>
                  </Box>
                </form>
                <Box mt={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => setIsModalOpen(true)}
                    className="permission-slip-button"
                  >
                    Sign A Permission Slip
                  </Button>
                </Box>
              </Container>
            </Box>
          )}
        </Grid>
        {!isMobile && (
          <Grid item xs={12} md={6} className="form-side">
            <Container maxWidth="xs">
              <Box className="logo-container">
                <img src={logo} alt="Company Logo" className="logo" />
                <Typography
                  variant="subtitle1"
                  align="center"
                  className="tagline"
                >
                  Seamless Journeys, Unforgettable Experiences
                </Typography>
              </Box>
              <form onSubmit={handleSubmit} className="login-form">
                <TextField
                  type="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  type="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="submit-button"
                >
                  Login
                </Button>
                <Box className="links">
                  <Link href="/signup" variant="body2">
                    Don't have an account? Signup
                  </Link>
                  <br />
                  <Link href="/forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Box>
              </form>
              <Box mt={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => setIsModalOpen(true)}
                  className="permission-slip-button"
                >
                  Sign A Permission Slip
                </Button>
              </Box>
            </Container>
          </Grid>
        )}
      </Grid>
      <TripSlipCodeModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleTripSlipSubmit}
      />
    </Box>
  );
};

export default Login;
