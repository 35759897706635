import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Grid,
    FormControl, InputLabel,
    Select,
    MenuItem, } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const grades_ = ['Pre-K','Kindergarten', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th'];

const EditBookingForm = ({ tripInfo, vendor, setIsEditing, onSave, onCancel }) => {
  const [formData, setFormData] = useState(tripInfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(formData);
    setIsEditing(false);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }} onClick={()=>setIsEditing(true)}>
        Edit Booking
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Trip Name"
            name="tripName"
            value={formData.tripName}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
        <FormControl fullWidth variant="outlined">
            <InputLabel>Grades</InputLabel>
            <Select
            multiple
            value={formData.grades}
            onChange={(e) =>
                setFormData((prevData) => ({
                ...prevData,
                grades: e.target.value,
                }))
            }
            label="Grades"
            >
            {grades_.map((grade) => (
                <MenuItem key={grade} value={grade}>
                {grade}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Max Number of Students"
            name="maxNumOfStudents"
            value={formData.maxNumOfStudents}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
                selected={formData.tripSlipDate}
                onChange={(date) => {
                  setFormData({ ...formData, tripSlipDate: date });
                }}
                customInput={
                  <TextField
                    label="Trip Slip Date"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                }
              />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Street Address"
            name="streetAddress"
            value={formData.departureLocation.streetAddress}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                departureLocation: {
                  ...prevData.departureLocation,
                  streetAddress: e.target.value,
                },
              }))
            }
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="City"
            name="city"
            value={formData.departureLocation.city}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                departureLocation: {
                  ...prevData.departureLocation,
                  city: e.target.value,
                },
              }))
            }
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="State"
            name="state"
            value={formData.departureLocation.state}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                departureLocation: {
                  ...prevData.departureLocation,
                  state: e.target.value,
                },
              }))
            }
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Zip Code"
            name="zipCode"
            value={formData.departureLocation.zipCode}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                departureLocation: {
                  ...prevData.departureLocation,
                  zipCode: e.target.value,
                },
              }))
            }
            fullWidth
            variant="outlined"
          />
        </Grid>
        {/* <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Start Time"
              value={formData.startTime}
              onChange={(time) => handleTimeChange('startTime', time)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="End Time"
              value={formData.endTime}
              onChange={(time) => handleTimeChange('endTime', time)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid> */}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button variant="outlined" color="secondary" onClick={onCancel} sx={{ ml: 2 }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditBookingForm;