import React, { useState, useEffect } from 'react';
import { Container, Box, TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';
import DashboardDrawer from '../../DashboardDrawers/VendorDashboardDrawer/VendorDashboardDrawer';
import { Link } from 'react-router-dom';

const BookingsTable = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredBookings, setFilteredBookings] = useState([]);

  const url = process.env.REACT_APP_SERVICE_URL;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const vendorId = sessionStorage.getItem('vendorId');
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${url}/bookings/vendor/${vendorId}`);
        setBookings(response.data);
        setFilteredBookings(response.data); // Initially, all bookings are shown
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      }
    };

    if (vendorId) {
      fetchBookings();
    }
  }, [url]);

  const handleSearch = () => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const results = bookings.filter(booking =>
      booking.organizer.firstName.toLowerCase().includes(lowerCaseSearchTerm) ||
      booking.organizer.lastName.toLowerCase().includes(lowerCaseSearchTerm) ||
      booking.bookingDate.includes(searchTerm) ||
      booking.status.toLowerCase().includes(lowerCaseSearchTerm)
    );
    setFilteredBookings(results);
  };

  return (
    <div>
        <DashboardDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
        <Container>
                <Box sx={{ mt: 4 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <TextField
                label="Search"
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ width: '50%' }}
                />
                <Button variant="contained" onClick={handleSearch} sx={{ ml: 2, backgroundColor: 'black', color: 'white' }}>
                Submit
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: 'black' }}>
                    <TableCell sx={{ color: 'white' }}>TripSlip Code</TableCell>
                    <TableCell sx={{ color: 'white' }}>Organizer Name</TableCell>
                    <TableCell sx={{ color: 'white' }}>Booking Date</TableCell>
                    <TableCell sx={{ color: 'white' }}>Time Slot</TableCell>
                    <TableCell sx={{ color: 'white' }}>Number Of People</TableCell>
                    <TableCell sx={{ color: 'white' }}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredBookings.length > 0 ? (
                    filteredBookings.map((booking) => (
                        <TableRow key={booking._id}>
                        <TableCell component={Link} to={`/view-booking/${booking._id}`}>{booking.tripSlipCode}</TableCell>
                        <TableCell>{`${booking.organizer.firstName} ${booking.organizer.lastName}`}</TableCell>
                        <TableCell>{booking.bookingDate}</TableCell>
                        <TableCell>{`${booking.timeSlot.startTime} - ${booking.timeSlot.endTime}`}</TableCell>
                        <TableCell>{booking.numberOfPeople}</TableCell>
                        <TableCell>{booking.status}</TableCell>
                        </TableRow>
                    ))
                    ) : (
                    <TableRow>
                        <TableCell colSpan={6} align="center">
                        No Bookings for this Vendor
                        </TableCell>
                    </TableRow>
                    )}
                </TableBody>
                </Table>
            </TableContainer>
            </Box>
        </Container>
    </div>
  );
};

export default BookingsTable;