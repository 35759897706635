import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Container, Typography, Dialog, DialogActions, DialogContent, DialogContentText, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import EditWeeklyAvailability from '../../WeeklyAvailability/EditWeeklyAvailability';

const EditVendorInformation = () => {
  const [vendorInfo, setVendorInfo] = useState({
    name: '',
    type: '',
    businessDescription: '',
    businessEmail: '',
    businessPhoneNumber: '',
    venueCapacity: '',
    businessLocation: {
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
    },
    weeklyAvailability: { // Initialize weeklyAvailability with default values or fetched data
      Monday: { startTime: '', endTime: '', isClosed: false },
      Tuesday: { startTime: '', endTime: '', isClosed: false },
      Wednesday: { startTime: '', endTime: '', isClosed: false },
      Thursday: { startTime: '', endTime: '', isClosed: false },
      Friday: { startTime: '', endTime: '', isClosed: false },
      Saturday: { startTime: '', endTime: '', isClosed: false },
      Sunday: { startTime: '', endTime: '', isClosed: false }
    }
  });
  const [logoFile, setLogoFile] = useState(null);
  const [businessMediaFiles, setBusinessMediaFiles] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const url = process.env.REACT_APP_SERVICE_URL;
  const vendorId = sessionStorage.getItem('vendorId');


  useEffect(() => {
    if (vendorId) {
      axios.get(`${url}/vendorinformation/${vendorId}`)
        .then(response => {
          // Check if the object is not empty
          if (Object.keys(response.data).length) {
            setVendorInfo(response.data);
          } else {
            // Reset to default structure instead of setting to null
          setVendorInfo({
            name: '',
            type: '',
            businessDescription: '',
            businessEmail: '',
            businessPhoneNumber: '',
            venueCapacity: '',
            businessLocation: {
              streetAddress: '',
              city: '',
              state: '',
              zipCode: '',
            },
            weeklyAvailability: { // Initialize weeklyAvailability with default values or fetched data
              Monday: { startTime: '', endTime: '', isClosed: false },
              Tuesday: { startTime: '', endTime: '', isClosed: false },
              Wednesday: { startTime: '', endTime: '', isClosed: false },
              Thursday: { startTime: '', endTime: '', isClosed: false },
              Friday: { startTime: '', endTime: '', isClosed: false },
              Saturday: { startTime: '', endTime: '', isClosed: false },
              Sunday: { startTime: '', endTime: '', isClosed: false }
            }
          });
          }
        })
        .catch(error => {
          console.error('Error fetching vendor information:', error);
          // Reset to default structure instead of setting to null
          setVendorInfo({
            name: '',
            type: '',
            businessDescription: '',
            businessEmail: '',
            businessPhoneNumber: '',
            venueCapacity: '',
            businessLocation: {
              streetAddress: '',
              city: '',
              state: '',
              zipCode: '',
            },
            weeklyAvailability: { // Initialize weeklyAvailability with default values or fetched data
              Monday: { startTime: '', endTime: '', isClosed: false },
              Tuesday: { startTime: '', endTime: '', isClosed: false },
              Wednesday: { startTime: '', endTime: '', isClosed: false },
              Thursday: { startTime: '', endTime: '', isClosed: false },
              Friday: { startTime: '', endTime: '', isClosed: false },
              Saturday: { startTime: '', endTime: '', isClosed: false },
              Sunday: { startTime: '', endTime: '', isClosed: false }
            }
          });
        });
    } else {
      // Reset to default structure instead of setting to null
      setVendorInfo({
        name: '',
        type: '',
        businessDescription: '',
        businessEmail: '',
        businessPhoneNumber: '',
        venueCapacity: '',
        businessLocation: {
          streetAddress: '',
          city: '',
          state: '',
          zipCode: '',
        },
        weeklyAvailability: { // Initialize weeklyAvailability with default values or fetched data
          Monday: { startTime: '', endTime: '', isClosed: false },
          Tuesday: { startTime: '', endTime: '', isClosed: false },
          Wednesday: { startTime: '', endTime: '', isClosed: false },
          Thursday: { startTime: '', endTime: '', isClosed: false },
          Friday: { startTime: '', endTime: '', isClosed: false },
          Saturday: { startTime: '', endTime: '', isClosed: false },
          Sunday: { startTime: '', endTime: '', isClosed: false }
        }
      });
    }
  }, [url, vendorId]);

  // useEffect to fetch existing vendor info if vendorId exists
  const handleSubmit = async (event) => {
    event.preventDefault(); // To prevent form submission from reloading the page
  
    const userId = sessionStorage.getItem('userId'); // Retrieve userId
    const formData = new FormData();
  
    // Append userId
    formData.append('userId', userId);
  
    // Append other vendorInfo fields to formData
    Object.entries(vendorInfo).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null) {
        // If the value is an object, stringify it
        formData.append(key, JSON.stringify(value));
      } else {
        // Append other values directly
        formData.append(key, value);
      }
    });
  
    // Append files if present
    if (logoFile) {
      formData.append('logoFile', logoFile);
    }
    businessMediaFiles.forEach((file, index) => formData.append(`businessMediaFiles[${index}]`, file));
  
    try {
      
      const response = await axios({
        method: vendorId ? 'put' : 'post',
        url: vendorId ? `${url}/vendorinformation/${vendorId}` : `${url}/vendorinformation`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 201) {
        sessionStorage.setItem('vendorId',response.data._id);
        setOpenModal(true); // Open modal on success
      } else if (response.status === 200) {
        setOpenModal(true); // Open modal on success
      }
    } catch (error) {
      console.error('Error saving vendor information:', error);
      // Error handling here
    }
  };  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Handling nested properties specifically for businessLocation
    if (name.includes("businessLocation.")) {
        const key = name.split(".")[1]; // Extract the specific property name
        setVendorInfo((prev) => ({
            ...prev,
            businessLocation: {
                ...prev.businessLocation,
                [key]: value,
            },
        }));
    } else {
        setVendorInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    }
    setHasChanges(true);
};
  
  const handleLogoUpload = (e) => {
    setLogoFile(e.target.files[0]);
    setHasChanges(true);
  };
  
  const handleMediaUpload = (e) => {
    setBusinessMediaFiles([...e.target.files]);
    setHasChanges(true);
  };

// Close button handler
  const handleClose = () => {
      setOpenModal(false);
      window.location.reload(); // Reload the page
  };

  const handleWeeklyAvailabilityChange = (newWeeklyAvailability) => {
    setVendorInfo((prevInfo) => ({
      ...prevInfo,
      weeklyAvailability: newWeeklyAvailability
    }));
    setHasChanges(true); // Assuming you have a mechanism to track unsaved changes
  };

  return (
    <div>
        <Container maxWidth="sm" sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {/* Overall Title */}
            <Typography variant="h4" component="h1" sx={{ width: '100%', textAlign: 'center', mb: 4 }}>
            Edit Vendor Information
            </Typography>
            <Box sx={{ flex: 1.5, mr: 4 }}>
                <Typography variant="body1">
                Please fill out the information. This will be what Organizers see when looking to book a TripSlip.
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'start', mt: 4, width: '100%', maxWidth: '1200px', mx: 'auto' }}>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: '800px' }}>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Business Name"
                    name="name"
                    autoComplete="name"
                    onChange={handleInputChange}
                    value={vendorInfo.name}
                    />
                    <InputLabel id="type-label">Business Type</InputLabel>
                      <Select
                          labelId="type-label"
                          id="type"
                          name="type"
                          value={vendorInfo.type}
                          onChange={handleInputChange}
                          label="Business Type"
                          fullWidth
                      >
                          <MenuItem value="Venue">Venue</MenuItem>
                          <MenuItem value="Experience">Experience</MenuItem>
                      </Select>
                    <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="businessDescription"
                    label="Business Description"
                    name="businessDescription"
                    autoComplete="businessDescription"
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                    value={vendorInfo.businessDescription}
                    />
                    <Box sx={{ mt: 2, border: '1px solid black', borderRadius: '4px', p: 2, width: '100%' }}>
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', color: 'white', bgcolor: 'black', p: 1, borderRadius: '4px' }}>
                        Contact Information
                        </Typography>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="businessEmail"
                        label="Business Email"
                        name="businessEmail"
                        autoComplete="email"
                        onChange={handleInputChange}
                        value={vendorInfo.businessEmail}
                        sx={{ mb: 1 }}
                        />
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="businessPhoneNumber"
                        label="Business Phone Number"
                        name="businessPhoneNumber"
                        autoComplete="tel"
                        onChange={handleInputChange}
                        value={vendorInfo.businessPhoneNumber}
                        sx={{ mb: 1 }}
                        />
                    </Box>

                    {/* Venue Capacity */}
                    <Box sx={{ mt: 2, border: '1px solid black', borderRadius: '4px', p: 2, width: '100%' }}>
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', color: 'white', bgcolor: 'black', p: 1, borderRadius: '4px' }}>
                        Venue Capacity
                        </Typography>
                        <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="venueCapacity"
                        label="Venue Capacity"
                        name="venueCapacity"
                        type="number"
                        onChange={handleInputChange}
                        value={vendorInfo.venueCapacity}
                        />
                    </Box>
                    {/* Business Location Group */}
                    <Box sx={{ mt: 2, border: '1px solid black', borderRadius: '4px', p: 2, width: '100%' }}>
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', color: 'white', bgcolor: 'black', p: 1, borderRadius: '4px' }}>
                        Business Location
                        </Typography>
                        {Object.keys(vendorInfo.businessLocation).map((key) => (
                        <TextField
                            key={key}
                            margin="normal"
                            required
                            fullWidth
                            id={key}
                            label={key.charAt(0).toUpperCase() + key.slice(1)}
                            name={`businessLocation.${key}`}
                            autoComplete={key}
                            onChange={handleInputChange}
                            value={vendorInfo.businessLocation[key]}
                            sx={{ mb: 1 }} // Optional: add spacing between fields if needed
                        />
                        ))}
                    </Box>
                    {/* File Inputs for logo and media files */}
                    <Typography variant="body1" sx={{ mt: 2 }}>Upload Logo</Typography>
                    <input
                    type="file"
                    onChange={handleLogoUpload}
                    />
                    <Typography variant="body1" sx={{ mt: 2 }}>Upload Business Media Files</Typography>
                    <input
                    type="file"
                    multiple
                    onChange={handleMediaUpload}
                    />
                     <EditWeeklyAvailability
                      weeklyAvailability={vendorInfo.weeklyAvailability}
                      onChange={handleWeeklyAvailabilityChange}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ 
                            mt: 3, 
                            mb: 2, 
                            backgroundColor: 'black', // Sets the button background color to black
                            color: 'white', // Sets the text color to white
                            '&:hover': {
                            backgroundColor: '#333', // Darker shade of black on hover for a subtle effect
                            },
                        }}
                        disabled={!hasChanges}
                        >
                        Submit
                    </Button>
                </Box>
            </Box>
        </Container>
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Information saved successfully.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} style={{ backgroundColor: 'black', color: 'white' }}>
                Close
            </Button>
            </DialogActions>
      </Dialog>
    </div>
  );
  
  
};

export default EditVendorInformation;