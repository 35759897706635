import React, { useState } from 'react';
import { Box, Button } from '@mui/material';

const ImageCarousel = ({ images }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    const nextImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
  
    const prevImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
  
    if (!images || images.length === 0) return null;
  
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
        <Button onClick={prevImage}>Prev</Button>
        <img src={images[currentImageIndex]} alt={`Business Media ${currentImageIndex}`} style={{ maxWidth: '100%', maxHeight: '300px', margin: '0 20px' }} />
        <Button onClick={nextImage}>Next</Button>
      </Box>
    );
};

export default ImageCarousel;
  