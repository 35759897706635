import React, { useState } from 'react';
import './TripSlipCodeModal.css';

const TripSlipModal = ({ isOpen, onClose, onSubmit }) => {
  const [tripSlipCode, setTripSlipCode] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = () => {
    onSubmit(tripSlipCode, setError);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-container">
      <div className="modal-backdrop" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <input
            type="text"
            value={tripSlipCode}
            onChange={(e) => setTripSlipCode(e.target.value)}
            placeholder="Enter TripSlip Code"
          />
          {error && <p className="error-message">{error}</p>}
          <div className="button-container">
            <button onClick={handleSubmit}>Submit</button>
            <button onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripSlipModal;
