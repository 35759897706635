// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

const OrganizerAccessControl = () => {
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const role = sessionStorage.getItem('role');
  //   const token = sessionStorage.getItem('token');

  //   if (!token || role !== 'organizer') {
  //     navigate('/');
  //   }
  //   // Dependencies array is empty because we only want this to run once when the component mounts
  // }, [navigate]);
};

export default OrganizerAccessControl;
