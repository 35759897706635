import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Box,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OrganizerDashboardDrawer from "../DashboardDrawers/OrganizerDashboardDrawer/OrganizerDashboardDrawer";
import { useParams } from "react-router-dom";
import OrganizationAccessControl from "../AccessControls/organizerAccessControl";

const ViewTripSlip = () => {
  OrganizationAccessControl();
  const { tripSlipId } = useParams();
  const [tripSlip, setTripSlip] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const role = sessionStorage.getItem("role");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSignedSlips, setFilteredSignedSlips] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const url = process.env.REACT_APP_SERVICE_URL;
    axios
      .get(`${url}/tripslips/${tripSlipId}`)
      .then((response) => {
        const data = response.data;

        // Parse stringified JSON fields
        data.departureLocation = JSON.parse(data.departureLocation);
        data.destinationLocation = JSON.parse(data.destinationLocation);
        data.grades = JSON.parse(data.grades[0]); // Assuming grades is an array with a single stringified JSON element

        // Parse signedTripSlips data
        data.signedTripSlips = data.signedTripSlips.map((slip) => ({
          ...slip,
          emergencyInformation: JSON.parse(slip.emergencyInformation),
          childData: JSON.parse(slip.childData),
        }));

        setTripSlip(data);
        setFilteredSignedSlips(data.signedTripSlips);
      })
      .catch((error) => console.error("Error fetching trip slip:", error));
  }, [tripSlipId]);

  useEffect(() => {
    if (searchTerm.length >= 2 && tripSlip) {
      const filtered = tripSlip.signedTripSlips.filter(
        (slip) =>
          slip.childData.childFirstName
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          slip.childData.childLastName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      );
      setFilteredSignedSlips(filtered);
    } else if (searchTerm.length === 0 && tripSlip) {
      setFilteredSignedSlips(tripSlip.signedTripSlips);
    }
  }, [searchTerm, tripSlip]);

  if (!tripSlip) {
    return <Typography variant="subtitle1">Loading...</Typography>;
  }

  const formatAddress = (location) => {
    return `${location.streetAddress}, ${location.city}, ${location.state}, ${location.zipCode}`;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const DrawerComponent =
    role === "organizer" ? OrganizerDashboardDrawer : null;

  const renderSignedSlipCard = (signedSlip, index) => (
    <Card key={index} sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="subtitle1">
          <strong>Guardian:</strong> {signedSlip.guardianFirstName}{" "}
          {signedSlip.guardianLastName}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Child:</strong> {signedSlip.childData.childFirstName}{" "}
          {signedSlip.childData.childLastName}
        </Typography>
        <Typography variant="subtitle1">
          <strong>Allergies:</strong>{" "}
          {signedSlip.childData.childAllergies || "None"}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle1">
            <strong>Emergency Information:</strong>
          </Typography>
          <Typography variant="body2">
            Contact: {signedSlip.emergencyInformation.emergencyContactFirstName}{" "}
            {signedSlip.emergencyInformation.emergencyContactLastName}
          </Typography>
          <Typography variant="body2">
            Relationship:{" "}
            {signedSlip.emergencyInformation.emergencyRelationship}
          </Typography>
          <Typography variant="body2">
            Best Contact Method:{" "}
            {signedSlip.emergencyInformation.emergencyBestContact}
          </Typography>
          <Typography variant="body2">
            Contact Info: {signedSlip.emergencyInformation.emergencyContactInfo}
          </Typography>
        </Box>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          <strong>Signed On:</strong>{" "}
          {new Date(signedSlip.createdAt).toLocaleDateString()}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle1">
            <strong>Signature:</strong>
          </Typography>
          <img
            src={signedSlip.guardianSignature}
            alt="Guardian Signature"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </CardContent>
    </Card>
  );

  const renderSignedSlipTable = () => (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Guardian</TableCell>
            <TableCell>Child</TableCell>
            <TableCell>Allergies</TableCell>
            <TableCell>Emergency Contact</TableCell>
            <TableCell>Signed On</TableCell>
            <TableCell>Signature</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredSignedSlips.map((signedSlip, index) => (
            <TableRow key={index}>
              <TableCell>{`${signedSlip.guardianFirstName} ${signedSlip.guardianLastName}`}</TableCell>
              <TableCell>{`${signedSlip.childData.childFirstName} ${signedSlip.childData.childLastName}`}</TableCell>
              <TableCell>
                {signedSlip.childData.childAllergies || "None"}
              </TableCell>
              <TableCell>
                {`${signedSlip.emergencyInformation.emergencyContactFirstName} ${signedSlip.emergencyInformation.emergencyContactLastName}`}
                <br />
                {`${signedSlip.emergencyInformation.emergencyRelationship}`}
                <br />
                {`${signedSlip.emergencyInformation.emergencyBestContact}: ${signedSlip.emergencyInformation.emergencyContactInfo}`}
              </TableCell>
              <TableCell>
                {new Date(signedSlip.createdAt).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <img
                  src={signedSlip.guardianSignature}
                  alt="Guardian Signature"
                  style={{ maxWidth: "100px", height: "auto" }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <DrawerComponent open={drawerOpen} toggleDrawer={toggleDrawer} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h4" gutterBottom>
                    {tripSlip.tripName}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Grades:</strong> {tripSlip.grades.join(", ")}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Max Number of Students:</strong>{" "}
                    {tripSlip.maxNumOfStudents}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Trip Slip Date:</strong>{" "}
                    {new Date(tripSlip.tripSlipDate).toLocaleDateString()}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Departure Location:</strong>{" "}
                    {formatAddress(tripSlip.departureLocation)}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Destination Location:</strong>{" "}
                    {formatAddress(tripSlip.destinationLocation)}
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong>Trip Slip Code:</strong> {tripSlip.tripSlipCode}
                  </Typography>
                  {tripSlip.customTerms && (
                    <Typography variant="subtitle1">
                      <strong>Terms & Conditions:</strong>{" "}
                      <Link
                        href={tripSlip.customTerms}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Document
                      </Link>
                    </Typography>
                  )}
                  {tripSlip.notes && (
                    <Typography variant="subtitle1">
                      <strong>Notes:</strong> {tripSlip.notes}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    Signed Trip Slips ({tripSlip.signedTripSlips.length}/
                    {tripSlip.maxNumOfStudents})
                  </Typography>
                  <Box sx={{ mb: 2 }}>
                    <TextField
                      label="Search by Child's Name"
                      variant="outlined"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      Enter at least 2 characters to search
                    </Typography>
                  </Box>
                  {isMobile
                    ? filteredSignedSlips.map(renderSignedSlipCard)
                    : renderSignedSlipTable()}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default ViewTripSlip;
