import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  TableSortLabel,
  Container,
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DashboardDrawer from "../DashboardDrawers/OrganizerDashboardDrawer/OrganizerDashboardDrawer";
import { useNavigate } from "react-router-dom";
import OrganizationAccessControl from "../AccessControls/organizerAccessControl";

const ViewTripSlips = () => {
  OrganizationAccessControl();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tripSlips, setTripSlips] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("tripName");
  const [sortDirection, setSortDirection] = useState("asc");
  const url = process.env.REACT_APP_SERVICE_URL;
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (!token) return;

    axios
      .get(`${url}/tripslips/organizer`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const data = response.data.map((tripSlip) => ({
          ...tripSlip,
          departureLocation: JSON.parse(tripSlip.departureLocation),
          destinationLocation: JSON.parse(tripSlip.destinationLocation),
        }));

        setTripSlips(data);
      })
      .catch((error) => console.error("Error fetching trip slips:", error));
  }, [url]);

  const handleSort = (field) => {
    const isAsc = sortField === field && sortDirection === "asc";
    setSortField(field);
    setSortDirection(isAsc ? "desc" : "asc");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleViewSignedSlips = (tripSlipId) => {
    navigate(`/view-trip-slip/${tripSlipId}`);
  };

  const sortedTripSlips = () => {
    return [...tripSlips].sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortField] > b[sortField]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const filteredTripSlips = () => {
    return sortedTripSlips().filter((tripSlip) => {
      const tripSlipDateString = new Date(
        tripSlip.tripSlipDate
      ).toLocaleDateString();
      return (
        tripSlip.tripName.toLowerCase().includes(searchText.toLowerCase()) ||
        formatLocation(tripSlip.destinationLocation)
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        tripSlipDateString.includes(searchText)
      );
    });
  };

  const formatLocation = (location) => {
    return `${location?.streetAddress}, ${location.city}, ${location.state}, ${location.zipCode}`;
  };

  const renderMobileCard = (tripSlip) => (
    <Card key={tripSlip._id} sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6">{tripSlip.tripName}</Typography>
        <Typography variant="body2">
          Date: {new Date(tripSlip.tripSlipDate).toLocaleDateString()}
        </Typography>
        <Typography variant="body2">
          Destination: {formatLocation(tripSlip.destinationLocation)}
        </Typography>
        <Typography variant="body2">
          Trip Slip Code: {tripSlip.tripSlipCode}
        </Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleViewSignedSlips(tripSlip._id)}
          sx={{ mt: 1 }}
        >
          View Slips
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <DashboardDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="xl">
          <Typography variant="h4" gutterBottom>
            Trip Slips
          </Typography>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="Search Trip Slips"
              variant="outlined"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              fullWidth
            />
          </Box>
          {isMobile ? (
            <Grid container spacing={2}>
              {filteredTripSlips().map(renderMobileCard)}
            </Grid>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: "70vh",
                overflow: "auto",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortField === "tripName"}
                        direction={
                          sortField === "tripName" ? sortDirection : "asc"
                        }
                        onClick={() => handleSort("tripName")}
                      >
                        Trip Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortField === "tripSlipDate"}
                        direction={
                          sortField === "tripSlipDate" ? sortDirection : "asc"
                        }
                        onClick={() => handleSort("tripSlipDate")}
                      >
                        Trip Slip Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Grades</TableCell>
                    <TableCell>Number of Students</TableCell>
                    <TableCell>Departure Location</TableCell>
                    <TableCell>Destination Location</TableCell>
                    <TableCell>Notes</TableCell>
                    <TableCell>Trip Slip Code</TableCell>
                    <TableCell>Number of Signed Slips</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredTripSlips().map((tripSlip) => (
                    <TableRow key={tripSlip._id}>
                      <TableCell>{tripSlip.tripName}</TableCell>
                      <TableCell>
                        {new Date(tripSlip.tripSlipDate).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{tripSlip.grades.join(", ")}</TableCell>
                      <TableCell>{tripSlip.maxNumOfStudents}</TableCell>
                      <TableCell>
                        {formatLocation(tripSlip.departureLocation)}
                      </TableCell>
                      <TableCell>
                        {formatLocation(tripSlip.destinationLocation)}
                      </TableCell>
                      <TableCell>{tripSlip.notes || "-"}</TableCell>
                      <TableCell>{tripSlip.tripSlipCode}</TableCell>
                      <TableCell>{tripSlip.numberOfSignedSlips}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => handleViewSignedSlips(tripSlip._id)}
                        >
                          View Slips
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {filteredTripSlips().length === 0 && (
            <Typography variant="body1" align="center" sx={{ mt: 2 }}>
              No trip slips found.
            </Typography>
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default ViewTripSlips;
