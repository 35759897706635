import React, { useState } from 'react';
import { Button, CircularProgress, Typography, Box, Paper, Grid, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { CheckCircle as CheckCircleIcon, Edit as EditIcon } from '@mui/icons-material';
import axios from 'axios';

const ConfirmTripSlipBooking = ({ tripInfo, vendor, setIsEditing }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const url = process.env.REACT_APP_SERVICE_URL;
  const token = sessionStorage.getItem('token');
  const config = { headers: { Authorization: `Bearer ${token}` } };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${url}/tripslips`, { ...tripInfo, vendorId: vendor._id }, config);
      response.then(()=>{
        setMessage('Booking successfully submitted!');
      })
      // Optionally reset form or redirect user
    } catch (error) {
      console.error('Error submitting booking:', error);
      setMessage('Failed to submit booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 4 }}>
          <Grid item>
            <Typography variant="h4">Confirm Your Booking</Typography>
          </Grid>
          <Grid item>
            <Button startIcon={<EditIcon />} onClick={()=> setIsEditing(true)}>
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
            <Typography variant="h6">Trip Details</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Trip Name" secondary={tripInfo.tripName} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Grades" secondary={tripInfo.grades.join(', ')} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Max Number of Students" secondary={tripInfo.maxNumOfStudents} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary="Trip Slip Date"
                  secondary={new Date(tripInfo.tripSlipDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit',
                  })}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Departure Location</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Street Address" secondary={tripInfo.departureLocation.streetAddress} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="City" secondary={tripInfo.departureLocation.city} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="State" secondary={tripInfo.departureLocation.state} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Zip Code" secondary={tripInfo.departureLocation.zipCode} />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Vendor Details</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Vendor Name" secondary={vendor.name} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="Start Time" secondary={tripInfo.startTime} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CheckCircleIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary="End Time" secondary={tripInfo.endTime} />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button fullWidth onClick={handleSubmit} variant="contained" color="primary">
                Confirm Booking
              </Button>
            )}
          </Grid>
          {message && (
            <Grid item xs={12}>
              <Typography variant='h6'>{message}</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Box>
  );
};

export default ConfirmTripSlipBooking;