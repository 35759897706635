import React, { useRef, useState } from "react";
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import "./SignTripSlip.css";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import ResponseModal from "../../ResponseModal/ResponseModal";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// Suppress ResizeObserver loop limit exceeded warning
const suppressResizeObserverError = () => {
  const resizeObserverErrDiv = document.createElement("div");
  resizeObserverErrDiv.id = "resize-observer-err-suppress";
  resizeObserverErrDiv.style.display = "none";
  document.body.appendChild(resizeObserverErrDiv);

  const resizeObserverErr = (event) => {
    if (event.message === "ResizeObserver loop limit exceeded") {
      event.stopImmediatePropagation();
    }
  };

  window.addEventListener("error", resizeObserverErr);
};

suppressResizeObserverError();

const SignTripSlip = () => {
  const location = useLocation();
  const [formData, setFormData] = useState({
    guardianFirstName: "",
    guardianLastName: "",
    emergencyInformation: {
      emergencyContactFirstName: "",
      emergencyContactLastName: "",
      emergencyRelationship: "",
      emergencyBestContact: "email",
      emergencyContactInfo: "",
    },
    childData: {
      childFirstName: "",
      childLastName: "",
      childAllergies: "",
    },
  });
  const tripSlipData = location.state?.tripSlipData;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsLinkClicked, setTermsLinkClicked] = useState(false);
  const [contactMethod, setContactMethod] = useState("email");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const sigPadRef = useRef(null);
  const url = process.env.REACT_APP_SERVICE_URL;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const clearSignature = () => {
    sigPadRef.current.clear();
  };

  if (!tripSlipData) return <Typography>Loading...</Typography>;

  const departureLocation = JSON.parse(tripSlipData.departureLocation);
  const destinationLocation = JSON.parse(tripSlipData.destinationLocation);

  const tripSlipDateFormatted = format(
    new Date(tripSlipData.tripSlipDate),
    "PPP"
  );
  const createdAtFormatted = format(new Date(tripSlipData.createdAt), "PPPp");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "emergencyBestContact") {
      setContactMethod(value);
    } else if (
      name.startsWith("emergency") ||
      name === "emergencyContactEmail" ||
      name === "emergencyContactPhone"
    ) {
      setFormData({
        ...formData,
        emergencyInformation: {
          ...formData.emergencyInformation,
          [name.includes("Email") || name.includes("Phone")
            ? "emergencyContactInfo"
            : name]: value,
          emergencyBestContact: contactMethod,
        },
      });
    } else if (name.startsWith("child")) {
      setFormData({
        ...formData,
        childData: {
          ...formData.childData,
          [name]: value,
        },
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const signatureImage = sigPadRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    const payload = new FormData();
    payload.append("tripSlipId", tripSlipData._id);
    payload.append("guardianFirstName", formData.guardianFirstName);
    payload.append("guardianLastName", formData.guardianLastName);
    payload.append(
      "emergencyInformation",
      JSON.stringify(formData.emergencyInformation)
    );
    payload.append("childData", JSON.stringify(formData.childData));
    payload.append("guardianSignatureImage", dataURItoBlob(signatureImage));

    try {
      const response = await axios.post(`${url}/signedtripslips`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        setModalHeader("Success");
        setModalMessage(
          `TripSlip Signed for ${formData.childData.childFirstName} ${formData.childData.childLastName}`
        );
      } else {
        setModalHeader("Error");
        setModalMessage("An error occurred. Please try again.");
      }
      setModalVisible(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      setModalHeader("Error");
      setModalMessage("An error occurred. Please try again.");
      setModalVisible(true);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    if (modalMessage.includes("Signed")) {
      navigate("/");
    }
  };

  const handleContactMethodChange = (event) => {
    setContactMethod(event.target.value);
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {tripSlipData.tripName}
        </Typography>
        <Typography variant="h5" gutterBottom>
          Trip Slip Code: {tripSlipData.tripSlipCode}
        </Typography>
        <Typography>Organizer: {tripSlipData.organizerName}</Typography>
        <Typography>Trip Slip Date: {tripSlipDateFormatted}</Typography>
        <Typography>
          Grades Involved: {tripSlipData.grades.join(", ")}
        </Typography>
        <Typography>
          Maximum Number of Students: {tripSlipData.maxNumOfStudents}
        </Typography>
        <Typography>
          Cost: {tripSlipData.cost ? tripSlipData.cost.numberDecimal : "$0"}
        </Typography>
        <Typography>Notes: {tripSlipData.notes}</Typography>
        <Typography>Created At: {createdAtFormatted}</Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box sx={{ mb: isMobile ? 2 : 0 }}>
            <Typography variant="h6">Departure Location</Typography>
            <Typography>
              <strong>{departureLocation.buildingName}</strong>
            </Typography>
            <Typography>{departureLocation.streetAddress}</Typography>
            <Typography>
              {departureLocation.city}, {departureLocation.state}{" "}
              {departureLocation.zipCode}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">Destination Location</Typography>
            <Typography>
              <strong>{destinationLocation.buildingName}</strong>
            </Typography>
            <Typography>{destinationLocation.streetAddress}</Typography>
            <Typography>
              {destinationLocation.city}, {destinationLocation.state}{" "}
              {destinationLocation.zipCode}
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <form onSubmit={handleSubmit}>
          <Typography variant="h5" gutterBottom>
            Guardian Information
          </Typography>
          <TextField
            fullWidth
            label="Guardian First Name"
            name="guardianFirstName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Guardian Last Name"
            name="guardianLastName"
            onChange={handleInputChange}
            margin="normal"
          />

          <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
            Child Information
          </Typography>
          <TextField
            fullWidth
            label="Child First Name"
            name="childFirstName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Child Last Name"
            name="childLastName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Child Allergies"
            name="childAllergies"
            onChange={handleInputChange}
            margin="normal"
          />

          <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
            Emergency Contact Information
          </Typography>
          <TextField
            fullWidth
            label="Emergency Contact First Name"
            name="emergencyContactFirstName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Emergency Contact Last Name"
            name="emergencyContactLastName"
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Relationship"
            name="emergencyRelationship"
            onChange={handleInputChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Best Way To Contact</InputLabel>
            <Select
              value={contactMethod}
              onChange={handleContactMethodChange}
              label="Best Way To Contact"
            >
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
            </Select>
          </FormControl>
          {contactMethod === "email" ? (
            <TextField
              fullWidth
              type="email"
              label="Email"
              name="emergencyContactEmail"
              onChange={handleInputChange}
              margin="normal"
            />
          ) : (
            <TextField
              fullWidth
              type="tel"
              label="Phone Number"
              name="emergencyContactPhone"
              onChange={handleInputChange}
              margin="normal"
            />
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                disabled={!termsLinkClicked}
              />
            }
            label={
              <Typography>
                I accept the terms of the field trip.{" "}
                {tripSlipData.customTerms && (
                  <Button
                    href={tripSlipData.customTerms}
                    target="_blank"
                    onClick={() => setTermsLinkClicked(true)}
                    rel="noopener noreferrer"
                  >
                    View Terms and Conditions
                  </Button>
                )}
              </Typography>
            }
          />

          <Box sx={{ mt: 2, mb: 2 }}>
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: isMobile ? 300 : 500,
                height: 200,
                className: "sigCanvas",
              }}
              ref={sigPadRef}
              backgroundColor="white"
            />
            <Button
              onClick={clearSignature}
              disabled={!termsAccepted}
              sx={{ mt: 1 }}
            >
              Clear Signature
            </Button>
          </Box>

          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Paper>

      {modalVisible && (
        <ResponseModal
          header={modalHeader}
          message={modalMessage}
          onClose={handleCloseModal}
        />
      )}
    </Container>
  );
};

export default SignTripSlip;
