import React, { useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
  InputAdornment,
  Grid,
  Paper,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff, Check, Clear } from "@mui/icons-material";
import { styled } from "@mui/system";

const Logo = styled("img")(({ theme }) => ({
  height: "40px",
  marginRight: "16px",
  [theme.breakpoints.down("sm")]: {
    height: "30px",
    marginRight: "8px",
  },
}));

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    contactPhoneNumber: "",
    role: "organizer",
    confirmPassword: "",
  });
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCheckboxChange = (event) => {
    setIsTermsChecked(event.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const passwordsMatch =
    formData.password &&
    formData.confirmPassword &&
    formData.password === formData.confirmPassword;
  const enableSubmit = isTermsChecked && passwordsMatch;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!enableSubmit) {
      alert(
        "Please make sure all fields are filled out correctly and that you have accepted the terms and conditions."
      );
      return;
    }

    const url = process.env.REACT_APP_SERVICE_URL;

    try {
      const response = await Axios.post(`${url}/users`, formData);
      if (response.status === 201) {
        navigate("/welcome", {
          state: { email: formData.email, role: formData.role },
        });
      } else {
        setError("Cannot create your account at this time. Try again later.");
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError(
          <>
            An account with that email already exists. Please{" "}
            <Link href="/" color="inherit">
              Login
            </Link>{" "}
            to sign in with that email or try another email.
          </>
        );
      } else {
        setError("Cannot create your account at this time. Try again later.");
      }
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#FFD800",
        minHeight: "100vh",
        pt: { xs: 2, sm: 5 },
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ py: { xs: 2, sm: 4 } }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Logo
              sx={{ height: isMobile ? 50 : 100, mb: isMobile ? 1 : 0 }}
              src="/assets/images/logo.png"
              alt="Logo"
            />
            <Typography
              variant={isMobile ? "h5" : "h4"}
              align="center"
              gutterBottom
            >
              Join TripSlip Today!
            </Typography>
          </Box>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    <span role="img" aria-label="Organizer">
                      📚
                    </span>{" "}
                    Organizer Account
                  </Typography>
                  <Typography variant="body1">
                    Manage your field trips effortlessly with verified venues,
                    digital permission slips, and comprehensive trip management.
                  </Typography>
                </Box>
                <Box sx={{ mb: 3, color: "grey.500", fontStyle: "italic" }}>
                  <Typography variant="h6" gutterBottom>
                    <span role="img" aria-label="Guardian">
                      👪
                    </span>{" "}
                    Guardian Account - Coming Soon
                  </Typography>
                  <Typography variant="body1">
                    Streamline your experience with online payments, easy access
                    to trip information, and secure sharing of allergy and
                    emergency details.
                  </Typography>
                </Box>
                <Box sx={{ color: "grey.500", fontStyle: "italic" }}>
                  <Typography variant="h6" gutterBottom>
                    <span role="img" aria-label="Vendor">
                      🛍️
                    </span>{" "}
                    Vendor Account - Coming Soon
                  </Typography>
                  <Typography variant="body1">
                    Boost your venue's visibility, gain insights into capacity
                    and scheduling, and communicate directly with organizers for
                    optimal coordination.
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 3 }}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="First Name"
                    name="firstName"
                    required
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Last Name"
                    name="lastName"
                    required
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Contact Phone Number"
                    name="contactPhoneNumber"
                    required
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    required
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    required
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={togglePasswordVisibility}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Confirm Password"
                    name="confirmPassword"
                    type={showPassword ? "text" : "password"}
                    required
                    fullWidth
                    margin="normal"
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={togglePasswordVisibility}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                          {formData.confirmPassword.length >=
                            formData.password.length &&
                            (passwordsMatch ? (
                              <Check color="success" />
                            ) : (
                              <Clear color="error" />
                            ))}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="role-label">Select a Role</InputLabel>
                    <Select
                      labelId="role-label"
                      name="role"
                      required
                      onChange={handleInputChange}
                      value={formData.role}
                    >
                      <MenuItem value="organizer">Organizer</MenuItem>
                      <MenuItem value="guardian" disabled>
                        Guardian (Coming Soon)
                      </MenuItem>
                      <MenuItem value="vendor" disabled>
                        Vendor (Coming Soon)
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isTermsChecked}
                        onChange={handleCheckboxChange}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Accept{" "}
                        <Link href="path_to_terms_of_service" target="_blank">
                          Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link href="path_to_privacy_policy" target="_blank">
                          Privacy Policy
                        </Link>
                      </Typography>
                    }
                  />

                  {error && (
                    <Paper
                      sx={{
                        p: 2,
                        mb: 2,
                        backgroundColor: "#ffcdd2",
                        color: "#d32f2f",
                      }}
                      className="error-box"
                    >
                      <Typography>{error}</Typography>
                      <IconButton
                        onClick={() => setError("")}
                        sx={{ position: "absolute", top: 8, right: 8 }}
                      >
                        <Clear />
                      </IconButton>
                    </Paper>
                  )}

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={!enableSubmit}
                    sx={{ mt: 2 }}
                  >
                    Create an Account
                  </Button>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Signup;
