import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const EditProfile = () => {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactPhoneNumber: "",
    role: "",
  });
  const [newPassword, setNewPassword] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const userId = sessionStorage.getItem("userId");
  const url = process.env.REACT_APP_SERVICE_URL;

  useEffect(() => {
    if (userId) {
      axios
        .get(`${url}/users/${userId}`)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => console.log(error));
    }
  }, [userId, url]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    setUserData((prevState) => ({
      ...prevState,
      [name]: trimmedValue,
    }));
    setIsEdited(true);
  };

  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
    setIsEdited(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowConfirmationDialog(true);
  };

  const handleConfirmUpdate = () => {
    const updatedData = { ...userData };
    if (newPassword) {
      updatedData.password = newPassword;
    }

    axios
      .put(`${url}/users`, updatedData)
      .then((response) => {
        if (response.status === 200) {
          setResponseHeader("Success");
          setResponseMessage("Your profile has been updated successfully.");
          setShowResponseModal(true);
          setIsEdited(false);
          setNewPassword("");
        }
      })
      .catch((error) => {
        setResponseHeader("Error");
        setResponseMessage("An error occurred while updating your profile.");
        setShowResponseModal(true);
      })
      .finally(() => {
        setShowConfirmationDialog(false);
      });
  };

  const handleCloseModal = () => {
    setShowResponseModal(false);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="edit-profile-form-container">
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Typography variant="h4" align="center" gutterBottom>
          {userData.role.charAt(0).toUpperCase() +
            userData.role.slice(1).toLowerCase()}{" "}
          Profile
        </Typography>
        <form onSubmit={handleSubmit} className="edit-profile-form">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Role"
                value={userData.role}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                name="firstName"
                value={userData.firstName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                name="lastName"
                value={userData.lastName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                type="email"
                value={userData.email}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contact Phone Number"
                name="contactPhoneNumber"
                type="tel"
                value={userData.contactPhoneNumber}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="New Password"
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={handlePasswordChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePasswordVisibility}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!isEdited}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Dialog open={showResponseModal} onClose={handleCloseModal}>
        <DialogTitle>{responseHeader}</DialogTitle>
        <DialogContent>
          <Typography>{responseMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showConfirmationDialog}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <DialogTitle>Confirm Update</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to update your profile?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowConfirmationDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleConfirmUpdate} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditProfile;
