import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button, Card, CardMedia, CardContent, CardActions } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ImageCarousel from '../../ImageCarousel';
import ViewWeeklyAvailability from '../../WeeklyAvailability/ViewWeeklyAvailability';

const ViewVendorInformation = () => {
  const [vendorInfo, setVendorInfo] = useState(null);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SERVICE_URL;
  const vendorId = sessionStorage.getItem('vendorId');

  useEffect(() => {
    if (vendorId) {
      axios.get(`${url}/vendorinformation/${vendorId}`)
        .then(response => {
          if (Object.keys(response.data).length) {
            setVendorInfo(response.data);
          } else {
            setVendorInfo(null);
          }
        })
        .catch(error => {
          console.error('Error fetching vendor information:', error);
          setVendorInfo(null);
        });
    } else {
      setVendorInfo(null);
    }
  }, [url, vendorId]);

  return (
    <div>
      <Container maxWidth="md" sx={{ p:3, mt: 4 }}>
        {vendorInfo ? (
          <Card raised sx={{ width: '100%' }}>
            <CardMedia
              component="img"
              height="200"
              image={vendorInfo.logo}
              alt="Business Logo"
            />
            <CardContent>
              
              <Typography gutterBottom variant="h5" component="div">
                {vendorInfo.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {vendorInfo.businessDescription}
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}><b>Email:</b> {vendorInfo.businessEmail}</Typography>
              <Typography variant="subtitle1"><b>Phone:</b> {vendorInfo.businessPhoneNumber}</Typography>
              <Typography variant="subtitle1"><b>Capacity:</b> {vendorInfo.venueCapacity}</Typography>
              <Typography variant="subtitle1">
                <b>Location:</b> {`${vendorInfo.businessLocation.streetAddress}, ${vendorInfo.businessLocation.city}, ${vendorInfo.businessLocation.state} ${vendorInfo.businessLocation.zipCode}`}
              </Typography>
            </CardContent>
            <ViewWeeklyAvailability weeklyAvailability={vendorInfo.weeklyAvailability} />
            
            <CardActions>
              <Button size="large" color="primary" onClick={() => navigate('/edit-vendor-information')}>
                Edit Profile
              </Button>
            </CardActions>
            {vendorInfo.businessMedia.length > 0 && <>
            <Typography variant="h6" sx={{ mt: 2, ml: 2 }}>Business Photos</Typography>
            <ImageCarousel images={vendorInfo.businessMedia || []} /></>}
          </Card>
        ) : (
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="body1">
              No information available. Please update your profile to appear in the TripSlip Vendor Database.
            </Typography>
            <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate('/edit-vendor-information')}>
              Add Information
            </Button>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default ViewVendorInformation;
